
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {Watch,Prop} from 'vue-property-decorator';

    import {
      IOrganization,
      IOrganizationRule,
      IUpdateOrganizationRuleParams,
      ISearchParams,
      IReason,
      IRuleVariables,
    } from '@/types';

    import {
        ReasonModule,
        RuleModule,
        SnackbarModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');
    const reasonNamespace = namespace('reason');

    @Component<OrganizationRule>({})
    export default class OrganizationRule extends Vue {
        public isLoadingModule: boolean = true;
        public validForm: boolean = false;
        public isLoading: boolean = false;
        public innerModel!: IOrganizationRule;
        public animalAge: number|null = null;
        public unchangedRules: string|null = null;
        public isChanged: boolean = false;

        @Prop({
            type: Object,
            required: true,
        })
        public rule!: IOrganizationRule;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @reasonNamespace.Getter('organizationReasonsEnabledList')
        public organizationReasonsEnabledList!: IReason[];

        get setIssueComment() {
          const rule = this.innerModel.rule.issue_comment ? this.innerModel.rule.issue_comment.split(':?') : null;
          let newSentence: string = '';
          if(!rule) return;
          rule.forEach((key: string) => {
            if (this.innerModel.used_variables[key]) {
              newSentence = newSentence+this.innerModel.used_variables[key];
            } else {
              newSentence = newSentence+key;
            }
          });

          return newSentence;
        }

        get renderSentence() {
          const rule = this.innerModel.rule.rule.split(':?');
          const newRules: Array<{}> = [];

          rule.forEach((key: string) => {
              const item = this.innerModel.rule.variables?.find((elm: IRuleVariables) => elm.name === key);

              if(item) {
                  newRules.push({
                      type: item?.type,
                      values: item?.values,
                      model: this.innerModel.used_variables,
                      name: item?.name,
                      label: item?.label,
                  });

              } else {
                  newRules.push({
                      type: 'string',
                      value: key,
                  });
              }
          });

          return newRules;
        }

        public ageRule = [
            (v: number) => (v < 101 && v > 0) || 'L\'age doit être compris entre 1 et 100',
        ];

        public onReasonsChanged() {
            if (this.innerModel.reasons.length === 0) {
                this.innerModel.organization_reason_issue = null;
            }
        }

        public updateRule() {
          this.isLoading = true;
          let params!: IUpdateOrganizationRuleParams;
          const forPassedVariables: object[] = [];
          const snackbarModule = getModule(SnackbarModule, this.$store);

          const reasons = this.innerModel.reasons.map((reason: IReason) => reason.id);

          if(Object.keys(this.innerModel.used_variables).length > 0) {

            this.renderSentence?.forEach((elm: any, i: number) => {
              if(elm.type !== 'string') {
                forPassedVariables.push({[elm.name as string]: elm.model[elm.name]});
              }
            });

            params = {
              id: this.innerModel.id,
              rule_id: this.innerModel.rule_id,
              reasons,
              organization_id: this.loggedOrganization.id,
              passed_variables: forPassedVariables,
              organization_reason_issue_id: this.innerModel.organization_reason_issue?.id,
            };

          } else {
            params = {
              id: this.innerModel.id,
              rule_id: this.innerModel.rule_id,
              reasons,
              organization_id: this.loggedOrganization.id,
              passed_variables: forPassedVariables,
              organization_reason_issue_id: this.innerModel.organization_reason_issue?.id,
            };
          }

          getModule(RuleModule, this.$store)
            .updateRule(params).then(() => {
                snackbarModule.displaySuccess('La règle a bien été modifiée');
                this.$emit('updated');
                this.isLoading = false;
            },
          );

        }

        public mounted() {
            const reasonModule = getModule(ReasonModule, this.$store);

            const params: ISearchParams = {
              page: 1,
              per_page: 500,
              organization_id: this.loggedOrganization.id,
            };

            reasonModule.fetchOrganizationReasonsEnabled(params)
              .then(() => {
                this.isLoadingModule = false;
              },
            );
        }

        public data() {
            return {
                innerModel: JSON.parse(JSON.stringify(this.rule)),
            };
        }

        @Watch('rule', {immediate: true, deep: true})
        private onRuleChange(newVal: IOrganizationRule) {
            this.innerModel = newVal ? JSON.parse(JSON.stringify(newVal)) : null;
            this.unchangedRules = JSON.stringify(newVal);
        }

        @Watch('innerModel', {immediate: true, deep: true})
        private innerModelChanged(newVal: IOrganizationRule) {
          if (this.unchangedRules) {
            this.isChanged = this.unchangedRules !== JSON.stringify(this.innerModel);
            return this.$emit('changing', this.unchangedRules !== JSON.stringify(this.innerModel));
          }
        }
    }
