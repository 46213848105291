
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';
    import RuleFormCard from '@/components/RuleFormCard.vue';
    import OrganizationRule from '@/components/OrganizationRule.vue';
    import {debounce} from 'debounce';
    import {Route} from 'vue-router';

    import {
        IOrganization,
        ISearchRulesParams,
        IOrganizationRule,
        IDeleteOrganizationRuleParams,
        IUpdateOrganizationRuleParams,
    } from '@/types';

    import {
        OrganizationModule,
        RuleModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');
    const ruleNamespace = namespace('rule');

    @Component<OrganizationRules>({
        components: {
            RuleFormCard,
            OrganizationRule,
        },
        beforeRouteLeave(to: Route, from: Route, next: any) {
            const self: OrganizationRules = this;

            if (self.hasChanged) {
                self.redirectTo = to;
                self.showAlert = true;
                self.hasChanged = false;
                next(false);
            } else {
                next();
            }
        },
    })
    export default class OrganizationRules extends Vue {
        public rulesIsFetched: boolean = false;
        public editDialog: boolean = false;
        public pageEnabled: number = 1;
        public perPageEnabled: number = 10;
        public pageDisabled: number = 1;
        public perPageDisabled: number = 10;
        public search: string = '';
        public expanded = [];

        public tabs: string = '';

        public itemsNav = [
            {
                title: 'Actif',
                value: 'activ',
            },
            {
                title: 'Inactif',
                value: 'inactiv',
            },
        ];

        public navItems: string[] = [
          'Motifs actifs',
          'Motifs inactifs',
        ];

        public hasChanged: boolean = false;
        public redirectTo: Route|null = null;
        public showAlert: boolean = false;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @ruleNamespace.Getter('enabledRulesList')
        public rulesEnabled!: IOrganizationRule;

        @ruleNamespace.Getter('disabledRulesList')
        public rulesDisabled!: IOrganizationRule;

        @ruleNamespace.Getter('getEnabledRulesTotal')
        public rulesTotalEnabled!: number;

        @ruleNamespace.Getter('getDisabledRulesTotal')
        public rulesTotalDisabled!: number;

        private debouncedSearchQuery = debounce(this.fetchAll, 300);

        public updatePerPageEnabled(e: number) {
            this.perPageEnabled = e;
            this.fetchAll();
        }

        public updatePageEnabled(e: number) {
            this.pageEnabled = e;
            this.fetchAll();
        }

        public updatePerPageDisabled(e: number) {
            this.perPageDisabled = e;
            this.fetchAll();
        }

        public updatePageDisabled(e: number) {
            this.pageDisabled = e;
            this.fetchAll();
        }

        public toggleActive(target: string, item: IOrganizationRule) {
            this.rulesIsFetched = false;

            const params: IUpdateOrganizationRuleParams = {
                id: item.id as string,
                organization_id: this.loggedOrganization.id,
                status: target,
            };

            getModule(RuleModule, this.$store).updateRule(params)
                .then(() => {
                    this.fetchAll();
                },
            );
        }

        public refreshReasons() {
            this.fetchAll()
        }

        public redirected(bol: boolean) {
            if (!bol) {
                this.showAlert = false;
                this.redirectTo = null;
                this.hasChanged = true;
            }

            if (this.redirectTo) {
               this.$router.push({path:this.redirectTo.path});
            }
        }

        get headers() {
            return [
                {
                    text: 'Titre',
                    value: 'type',
                    class: 'col-2',
                    sortable: false,
                },
                {
                    text: 'Règle',
                    value: 'message_type',
                    class: 'col-6',
                    sortable: false,
                },
                {
                    text: 'Motif(s)',
                    value: 'reasons',
                    class: 'col-1',
                    sortable: false,
                },
                {
                    text: 'Issue',
                    value: 'issue',
                    class: 'col-1',
                    sortable: false,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    class: 'col-4',
                    sortable: false,
                },
            ];
        }

        public getFormattedIssueComment(item: any) {
            return item.rule.issue_comment
                .split(':?')
                .map((rulePart: string) => item.used_variables[rulePart] ?? rulePart)
                .join('')
            ;
        }

        public innerRuleChanged(val: boolean) {
            this.hasChanged = val;
            return this.hasChanged;
        }

        public getSentence(elm: IOrganizationRule) {
          const rule = elm.rule.rule.split(':?');
          let newSentence: any = '';

          rule.forEach((key: string) => {
            if (elm.used_variables[key]) {
              newSentence = newSentence+elm.used_variables[key];

            } else {
              newSentence = newSentence+key;
            }
          });

          return newSentence;
        }

        public fetchAll() {
            this.rulesIsFetched = false;

            const paramsEnabled: ISearchRulesParams = {
                page: this.pageEnabled,
                page_size: this.perPageEnabled,
                organization_id: this.loggedOrganization.id,
                title: this.search,
                status: 'enabled',
            };

            const paramsDisabled: ISearchRulesParams = {
                page: this.pageEnabled,
                page_size: this.perPageEnabled,
                organization_id: this.loggedOrganization.id,
                title: this.search,
                status: 'disabled',
            };

            const rulesEnabledPromise = getModule(RuleModule, this.$store).fetchRulesEnabled(paramsEnabled);
            const rulesDisabledPromise = getModule(RuleModule, this.$store).fetchRulesDisabled(paramsDisabled);

            return Promise.all([rulesEnabledPromise, rulesDisabledPromise])
                .then(() => {
                    this.rulesIsFetched = true;
                },
            );

        }

        public createRule() {
            this.editDialog = false;
            this.fetchAll();
        }

        public closeAll() {
            this.editDialog = false;
            this.hasChanged = false;
            this.expanded = [];
            this.fetchAll();
        }

        public deleteRule(item: IOrganizationRule) {
            this.rulesIsFetched = false;

            const params: IDeleteOrganizationRuleParams = {
                id: item.id as string,
                organization_id: this.loggedOrganization.id as string,
            };

            getModule(RuleModule, this.$store).deleteRule(params)
                .then(() => {
                    this.fetchAll();
                },
            );
        }

        public mounted() {
            const organizationModule = getModule(OrganizationModule, this.$store);

            organizationModule.fetchOrganization(this.loggedOrganization.id)
                .then(() => {
                    this.fetchAll();
                },
            );
        }

        @Watch('search')
        public onChangeSearch(newVal: string) {
            this.debouncedSearchQuery();
        }
    }

