
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IAccount,
        IAccountRole,
        IOrganization,
        IVeterinarian,
    } from '@/types';

    import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
    import VeterinarianFormCard from '@/components/VeterinarianFormCard.vue';
    import VeterinarianFullFormCard from '@/components/VeterinarianFullFormCard.vue';

    import {
        SnackbarModule,
        VeterinarianModule,
        OrganizationModule,
    } from '@/store/modules';

    import {formHelper} from '@/helpers';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

    @Component<VeterinarianUsers>({
        components: {
            ConfirmDialog,
            VeterinarianFormCard,
            VeterinarianFullFormCard,
        },
    })
    export default class VeterinarianUsers extends Vue {
        public isLoading: boolean = true;

        public createDialog: boolean = false;
        public confirmDeleteDialog: boolean = false;
        public inviteDialog: boolean = false;
        public editDialog: boolean = false;

        public sendingDeleteVeterinarianRequest: boolean = false;
        public sendingInviteVeterinarianRequest: boolean = false;

        public veterinarianToDelete: IVeterinarian|null = null;
        public veterinarianToEdit: IVeterinarian|null = null;
        public veterinarianToInvite: IVeterinarian|null = null;

        public inviteVeterinarianForm: boolean = false;
        public veterinarianToInviteEmail: string|null = null;

        public veterinarianEmailRules = formHelper.getEmailsRules();

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        @organizationNamespace.Getter('veterinariansList')
        public veterinariansList!: IVeterinarian[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get headers() {
            return [
                {
                    text: 'Nom',
                    value: 'full_name',
                },
                {
                    text: 'Email',
                    value: 'email',
                },
                {
                    text: 'Statut',
                    value: 'role',
                    sortable: false,
                },
                {
                    text: 'Actions',
                    value: 'action',
                    align: 'center',
                    sortable: false,
                },
            ];
        }

        public canDeleteUser(veterinarian: IVeterinarian) {
            const role = veterinarian.role;
            const loggedRole = this.loggedAccountRoles[0].role.name;

            return (role !== 'organization_admin' && role !== 'organization_owner') || this.$hasRole('owner') || (loggedRole === 'organization_owner' && role !== 'organization_admin');
        }

        public canDowngradeUser(veterinarian: IVeterinarian) {
            return veterinarian.has_account && veterinarian.role !== 'organization_admin' && veterinarian.role !== 'organization_member' && veterinarian.role !== 'organization_owner' || (this.$hasRole('owner') && veterinarian.role !== 'organization_member' && veterinarian.has_account);
        }

        public canPromoteUser(veterinarian: IVeterinarian) {
            return veterinarian.role === 'organization_member' && this.$hasRole('owner');
        }

        public editVeterinarian(veterinarian: IVeterinarian) {
            this.veterinarianToEdit = Object.assign({}, veterinarian);
            this.editDialog = true;
        }

        public deleteVeterinarian(veterinarian: IVeterinarian) {
            this.veterinarianToDelete = veterinarian;
            this.confirmDeleteDialog = true;
        }

        public inviteVeterinarian(veterinarian: IVeterinarian) {
            this.veterinarianToInvite = veterinarian;
            this.veterinarianToInviteEmail = null;
            this.inviteDialog = true;
        }

        public veterinarianCreated() {
            this.createDialog = false;
            getModule(OrganizationModule, this.$store)
                .fetchOrganizationVeterinariansList(this.loggedOrganization.id)
            ;
        }

        public sendDeleteVeterinarianRequest() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.sendingDeleteVeterinarianRequest = true;

            if (this.veterinarianToDelete) {
                getModule(OrganizationModule, this.$store)
                    .deleteOrganizationVeterinarian({
                        veterinarian_id: this.veterinarianToDelete.id,
                        organization_id: this.loggedOrganization.id,
                    })
                    .then(() => {
                        this.confirmDeleteDialog = false;
                        this.veterinarianToDelete = null;

                        getModule(OrganizationModule, this.$store)
                            .fetchOrganizationVeterinariansList(this.loggedOrganization.id)
                            .then(() => snackbarModule.displaySuccess('Le vétérinaire a bien été supprimé !'))
                        ;
                    })
                    .catch(() => snackbarModule.displayError())
                    .finally(() => this.sendingDeleteVeterinarianRequest = false)
                ;
            }
        }

        public downgradeVeterinarian(veterinarian: IVeterinarian) {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            getModule(VeterinarianModule, this.$store)
                .changeVeterinarianRole({
                    veterinarian_id: veterinarian.id,
                    organization_id: this.loggedOrganization.id,
                    role: 'organization_member',
                })
                .then(() => snackbarModule.displaySuccess('L\'utilisateur a bien été rétrogradé !'))
                .catch(() => snackbarModule.displayError())
            ;
        }

        public promoteVeterinarian(veterinarian: IVeterinarian) {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            getModule(VeterinarianModule, this.$store)
                .changeVeterinarianRole({
                    veterinarian_id: veterinarian.id,
                    organization_id: this.loggedOrganization.id,
                    role: 'organization_admin',
                })
                .then(() => snackbarModule.displaySuccess('L\'utilisateur a bien été promu administrateur !'))
                .catch(() => snackbarModule.displayError())
            ;
        }

        public sendInviteVeterinarianRequest() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.sendingInviteVeterinarianRequest = true;

            if (this.veterinarianToInvite) {
                getModule(VeterinarianModule, this.$store)
                    .inviteVeterinarian({
                        veterinarian_id: this.veterinarianToInvite.id,
                        organization_id: this.loggedOrganization.id,
                        email: this.veterinarianToInviteEmail as string,
                    })
                    .then(() => {
                        this.inviteDialog = false;
                        this.veterinarianToInvite = null;

                        snackbarModule.displaySuccess('L\'invitation a bien été envoyée !');
                    })
                    .catch(() => snackbarModule.displayError())
                    .finally(() => this.sendingInviteVeterinarianRequest = false)
                ;
            }
        }

        public created() {
            getModule(OrganizationModule, this.$store)
                .fetchOrganizationVeterinariansList(this.loggedOrganization.id)
                .then(() => this.isLoading = false)
            ;
        }
    }
