
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';
    import {formHelper} from '@/helpers';

    import {
        IOrganization,
        IOrganizationFees,
        IUpdateOrganizationFeesParams,
    } from '@/types';

    import {
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');

    @Component<OrganizationProfileFees>({
        components: {
        },
    })
    export default class OrganizationProfileFees extends Vue {
        public formValid: boolean = false;
        public feePriceRules = formHelper.getOrganizationFeePriceRules();
        public feeNameRules = formHelper.getOrganizationFeeNameRules();
        public newFeeName: string = '';
        public newFeePrice: number|null = null;
        public statusForm: string = 'add';
        public feeInd: number = 0;

        public innerModel!: IOrganization;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        public addNewFee() {
            const newFee: IOrganizationFees = {
                name: this.newFeeName,
                price: this.newFeePrice,
            };

            this.innerModel.fees.push(newFee);
            this.submitUpdateOrganizationFees();
            this.newFeeName = '';
            this.newFeePrice = null;
            (this.$refs.form as any).reset();
        }

        public deleteFee(index: number) {
            this.innerModel.fees.splice(index, 1);
            this.submitUpdateOrganizationFees();
        }

        get params(): IUpdateOrganizationFeesParams {
            return  {
                organization_id: this.loggedOrganization.id,
                fees: this.innerModel.fees,
            };
        }

        public submitUpdateOrganizationFees() {
            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            organizationModule
                .update(this.params as IUpdateOrganizationFeesParams)
                .then((organization: IOrganization) => {
                    const name = this.loggedOrganization.name;
                    snackbarModule.displaySuccess(`Les honoraires de ${name} ont bien été modifiés !`);
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
            ;
        }

        public updateFees() {
            this.statusForm = 'update';
            this.innerModel.fees[this.feeInd].name = this.newFeeName;
            this.innerModel.fees[this.feeInd].price = this.newFeePrice;
            this.submitUpdateOrganizationFees();
            this.statusForm = 'add';
        }

        private data() {
            return {
                innerModel: this.loggedOrganization ? JSON.parse(JSON.stringify(this.loggedOrganization)) : null,
            };
        }

        @Watch('loggedOrganization', {immediate: true})
        private onLoggedOrganizationChange(newVal: IOrganization) {
            this.innerModel = newVal ? JSON.parse(JSON.stringify(newVal)) : null;
        }
    }
