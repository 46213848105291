
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IAccount,
        IOrganization,
        IOrganizationJoinRequest,
    } from '@/types';

    import {
        SnackbarModule,
        OrganizationModule,
    } from '@/store/modules';

    import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

    @Component<JoinRequestsTable>({
        components: {
            ConfirmDialog,
        },
    })
    export default class JoinRequestsTable extends Vue {
        public isLoading: boolean = true;

        public cancelJoinRequestDialog: boolean = false;
        public sendingCancelJoinRequestRequest: boolean = false;
        public joinRequestToCancel: IOrganizationJoinRequest|null = null;

        public updatingJoinRequest: boolean[] = [];

        public DATETIME_SHORT = DateTime.DATETIME_SHORT;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @organizationNamespace.Getter('joinRequestsList')
        public joinRequestsList!: IOrganizationJoinRequest[];

        get headers() {
            return [
                {
                    text: 'Utilisateur',
                    value: 'full_name',
                },
                {
                    text: 'Email',
                    value: 'email',
                },
                {
                    text: 'Type',
                    value: 'type',
                    sortable: false,
                },
                {
                    text: 'Expire le',
                    value: 'valid_until',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                },
            ];
        }

        public cancelJoinRequest(joinRequest: IOrganizationJoinRequest) {
            this.joinRequestToCancel = joinRequest;
            this.cancelJoinRequestDialog = true;
        }

        public updateJoinRequest(joinRequest: any, status: string) {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.updatingJoinRequest[joinRequest.id] = true;

            getModule(OrganizationModule, this.$store)
                .updateJoinRequest({
                    join_request_id: joinRequest.id,
                    organization_id: this.loggedOrganization.id,
                    status,
                })
                .then(() => {
                    if (status === 'accepted') {
                        snackbarModule.displaySuccess('La demande a bien été acceptée');
                    } else if (status === 'refused') {
                        snackbarModule.displaySuccess('La demande a bien été refusée');
                    }
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.updatingJoinRequest[joinRequest.id] = false)
            ;
        }

        public sendCancelJoinRequestRequest() {
            if (!this.joinRequestToCancel) {
                return;
            }

            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.updatingJoinRequest[this.joinRequestToCancel.id as any] = true;

            getModule(OrganizationModule, this.$store)
                .cancelJoinRequest({
                    join_request_id: this.joinRequestToCancel.id,
                    organization_id: this.loggedOrganization.id,
                })
                .then(() => {
                    snackbarModule.displaySuccess('L\'invitation a bien été annulée');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => {
                    this.cancelJoinRequestDialog = false;
                    this.updatingJoinRequest[(this.joinRequestToCancel as IOrganizationJoinRequest).id as any] = false;
                    this.joinRequestToCancel = null;
                })
            ;
        }

        public created() {
            getModule(OrganizationModule, this.$store)
                .fetchOrganizationJoinRequestsList(this.loggedOrganization.id)
                .then(() => this.isLoading = false)
            ;
        }
    }
