
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Model} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IOrganization,
        ICreateReasonParams,
        IUpdateReasonParams,
        IReason,
    } from '@/types';

    import DurationInput from '@/components/forms/DurationInput.vue';

    import {
        ReasonModule,
        SnackbarModule,
    } from '@/store/modules';

    import {formHelper} from '@/helpers';

    const organizationNamespace = namespace('organization');

    @Component<ReasonFormCard>({
        components: {
            DurationInput,
        },
    })
    export default class ReasonFormCard extends Vue {
        public isSubmittingReason: boolean = false;
        public reasonFormValid: boolean = false;

        public colorModal: boolean = false;

        public innerModel!: IReason;

        public reasonDurationRules = formHelper.getReasonDurationRules();
        public reasonNameRules = formHelper.getReasonNameRules();
        public minIntervalRules = formHelper.getMinIntervalRules();
        public maxIntervalRules = formHelper.getMaxIntervalRules();

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization|null;

        @Model('input', {type: Object}) public readonly value!: IReason;

        get cardTitle(): string {
            if (this.innerModel.id) {
                return 'Modifier mon motif de consultation';
            }

            return 'Ajouter un motif de consultation';
        }

        get submitButtonText(): string {
            if (this.innerModel.id) {
                return 'Modifier';
            }

            return 'Confirmer';
        }

        get params(): ICreateReasonParams|IUpdateReasonParams {
            const params: ICreateReasonParams|IUpdateReasonParams = {
                name: this.innerModel.name,
                color: this.innerModel.color,
                duration: Number(this.innerModel.duration).valueOf(),
                interval: this.innerModel.interval,
                send_email: this.innerModel.send_email,
                send_sms: this.innerModel.send_sms,
                status: 'enabled',
                organization_id: (this.loggedOrganization as IOrganization).id,
            };

            if (this.innerModel.id) {
                (params as IUpdateReasonParams).reason_id = this.innerModel.id;
            }

            return params;
        }

        get hasNotifications(): boolean {
            return this.innerModel.send_email && this.innerModel.send_sms;
        }

        set hasNotifications(val: boolean) {
            this.innerModel.send_email = val;
            this.innerModel.send_sms = val;
        }

        public submitButtonClicked() {
            if (this.innerModel.id) {
                return this.updateReason();
            }

            return this.createReason();
        }

        public createReason() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.isSubmittingReason = true;

            getModule(ReasonModule, this.$store)
                .createReason(this.params as ICreateReasonParams)
                .then((reason: IReason) => {
                    snackbarModule.displaySuccess('Votre motif de consultation a bien été créé !');
                    this.$emit('input');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingReason = false)
            ;
        }

        public updateReason() {
            const snackbarModule = getModule(SnackbarModule, this.$store);
            const method = this.innerModel.is_private ? 'updatePrivateReason' : 'updatePublicReason';

            this.isSubmittingReason = true;

            getModule(ReasonModule, this.$store)
                [method](this.params as IUpdateReasonParams)
                .then((reason: IReason) => {
                    snackbarModule.displaySuccess('Votre motif de consultation a bien été modifié !');
                    this.$emit('input', reason);
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingReason = false)
            ;
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.value),
            };
        }
    }
