
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import JoinRequestsTable from '@/components/organization-users/JoinRequestsTable.vue';
    import ParaveterinarianUsersTable from '@/components/organization-users/ParaveterinarianUsersTable.vue';
    import VeterinarianUsersTable from '@/components/organization-users/VeterinarianUsersTable.vue';

    import {namespace} from 'vuex-class';

    import {
        IOrganization,
    } from '@/types';

    const organizationNamespace = namespace('organization');

    @Component<OrganizationUsers>({
        components: {
            JoinRequestsTable,
            ParaveterinarianUsersTable,
            VeterinarianUsersTable,
        },
    })
    export default class OrganizationUsers extends Vue {
        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;
    }
