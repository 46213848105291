
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';

    import {
      IAgenda,
        IOrganization, IVeterinarian,
    } from '@/types';

    import AgendaFormCard from '@/components/AgendaFormCard.vue';
    import VeterinarianFormCard from '@/components/VeterinarianFormCard.vue';

    const organizationNamespace = namespace('organization');

    @Component<CreateAgendaCard>({
        components: {
            AgendaFormCard,
            VeterinarianFormCard,
        },
    })
    export default class CreateAgendaCard extends Vue {
        public step: number = 0;
        public isLoading: boolean = true;

        public veterinarians: IVeterinarian[] = [];

        public veterinarian: IVeterinarian | null = null;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization|null;

        get veterinariansWithNoAgenda() {
            return this.veterinarians.filter((veterinarian: IVeterinarian) => veterinarian.agendas_count === 0);
        }

        get agenda() {
            return {
                color: '#CCCCCC',
                is_public: true,
                is_directly_bookable: true,
                veterinarian: this.veterinarian,
            };
        }

        set agenda(val: string | undefined | null | IAgenda) {
            return;
        }

        public filterVeterinariansWithNoAgenda(item: {first_name: string, last_name: string}, queryText: string) {
            const textOne = item.first_name.toLowerCase();
            const textTwo = item.last_name.toLowerCase();
            const searchText = queryText.toLowerCase();

            return !searchText.split(' ').some((value: string) => {
                return textOne.indexOf(value.trim()) === -1 &&
                    textTwo.indexOf(value.trim()) === -1;
            });
        }

        public createNewVeterinarian() {
            this.veterinarian = null;
            this.step = 2;
        }

        private created() {
            this.$api.organization
                .veterinariansList((this.loggedOrganization as IOrganization).id)
                .then((veterinarians: IVeterinarian[]) => {
                    this.veterinarians = veterinarians;

                    if (this.veterinariansWithNoAgenda.length > 0) {
                        this.step = 0;
                    } else {
                        this.createNewVeterinarian();
                    }
                })
                .finally(() => this.isLoading = false)
            ;
        }
    }
