
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {formHelper} from '@/helpers';
    import {Watch} from 'vue-property-decorator';
    import {NavigationGuardNext, Route} from 'vue-router';
    import {debounce} from 'debounce';

    import {
        IOrganization,
        IReason,
        ISearchParams,
    } from '@/types';

    import ReasonFormCard from '@/components/ReasonFormCard.vue';
    import DurationInput from '@/components/forms/DurationInput.vue';

    import {
        SnackbarModule,
        ReasonModule,
    } from '@/store/modules';

    const reasonNamespace = namespace('reason');
    const organizationNamespace = namespace('organization');

    @Component<OrganizationReasons>({
        components: {
            ReasonFormCard,
            DurationInput,
        },
        beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
            const self: OrganizationReasons = this;

            if (self.redirectTo) {
                return next();
            }

            if (self.innerReasonsEnabledChanged) {
                self.redirectTo = to;
                self.showAlert = true;
                self.beforeReasonsEnabled = null;
                next(false);
            } else {
                self.showAlert = false;
                next();
            }
        },
    })

    export default class OrganizationReasons extends Vue {
        public reasonDurationRules = formHelper.getReasonDurationRules();
        public reasonNameRules = formHelper.getReasonNameRules();
        public minIntervalRules = formHelper.getMinIntervalRules();
        public maxIntervalRules = formHelper.getMaxIntervalRules();

        public isFetched: boolean = true;

        public innerReasonsEnabled: IReason[] = [];
        public innerReasonsDisabled: IReason[] = [];

        public beforeReasonsEnabled: string|null = null;
        public showAlert: boolean = false;
        public redirectTo: Route|null = null;

        public search: string = '';
        public toggleDialogType: string|null = null;
        public toggleAdvancedOptions: string|null = null;
        public toggleAdvancedOptionsDisabled: string|null = null;
        public sendingToggleAction: boolean = false;

        public formCheck: boolean = false;
        public confirmDeleteDialog: boolean = false;
        public editDialog: boolean = false;
        public colorModal: boolean = false;
        public toggleDialog: boolean = false;
        public sendingDeleteReasonRequest: boolean = false;

        public pageEnabled: number = 1;
        public perPageEnabled: number = 10;
        public pageDisabled: number = 1;
        public perPageDisabled: number = 10;

        public isSavingReasonsEnabled: boolean = false;

        public reasonToDelete: IReason|null = null;
        public reasonToEdit: IReason|null = null;

        public alertMessage: {} =  {
            title : '',
            text: '',
            alert: '',
        };


        public tabs: string = '';

        public itemsNav = [
            {
                title: 'Actif',
                value: 'activ',
            },
            {
                title: 'Inactif',
                value: 'inactiv',
            },
        ];

        public navItems: string[] = [
          'Motifs actifs',
          'Motifs inactifs',
        ];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @reasonNamespace.Getter('organizationReasonsDisabledList')
        public organizationReasonsDisabled!: IReason[];

        @reasonNamespace.Getter('organizationReasonsEnabledList')
        public organizationReasonsEnabled!: IReason[];

        @reasonNamespace.Getter('reasonsTotalEnabled')
        public reasonsTotalEnabled!: number;

        @reasonNamespace.Getter('reasonsTotalDisabled')
        public reasonsTotalDisabled!: number;

        @reasonNamespace.Action('bulkUpdateReasons')
        public bulkUpdateReasons!: any;

        @reasonNamespace.Action('updatePublicReason')
        public updatePublicReason!: (reason: IReason) => Promise<any>;

        @reasonNamespace.Action('updatePrivateReason')
        public updatePrivateReason!: (reason: IReason) => Promise<any>;

        private debouncedSearchQuery = debounce(this.fetchReasonsList, 300);

        public redirected(bol: boolean) {
            if (!bol) {
                this.showAlert = false;
                this.redirectTo = null;
            }

            if (this.redirectTo) {
               this.$router.push({path:this.redirectTo.path});
            }
        }

        get innerReasonsEnabledChanged() {
            return this.beforeReasonsEnabled !== JSON.stringify(this.innerReasonsEnabled);
        }

        get tabMessages() {
            return [
                {
                    title: 'Désactiver tous les motifs',
                    text: 'Etes-vous sûr de vouloir desactiver tous les motifs ?',
                    alert: 'Les motifs désactivés seront disponibles dans le tableau des motifs désactivés, ils peuvent être activés de nouveau.',
                    button: 'Désactiver',
                },
                {
                    title: 'Désactiver toutes les notifications',
                    text: 'Etes-vous sûr de vouloir desactiver toutes les notifications ?',
                    alert: 'Les notifications permettent de prevenir le client d\'une modification sur les futures rendez-vous. Si ils sont désactivés le client ne sera pas prevenu.',
                    button: 'Désactiver',
                },
                {
                    title: 'Activer toutes les notifications',
                    text: 'Etes-vous sûr de vouloir activer toutes les notifications ?',
                    alert: 'Grace aux notifications, les clients seront prévenus des modifications sur les futurs rendez-vous.',
                    button: 'Activer',
                },
                {
                    title: 'Activer tous les motifs',
                    text: 'Etes-vous sûr de vouloir activer tous les motifs ?',
                    alert: 'Les motifs activés seront disponibles dans le tableau des motifs activés, ils peuvent être désactivés de nouveau.',
                    button: 'Activer',
                },
            ];
        }

        get advancedOptions() {
            return [
                {
                    text: 'Désactiver toutes les notifications',
                    value: 'disable-notifications',
                },
                 {
                    text: 'Activer toutes les notifications',
                    value: 'enable-notifications',
                },
                {
                    text: 'Désactiver tous les motifs',
                    value: 'disable-reasons',
                },
            ];
        }

        get advancedOptionsDisabled() {
            return [
                {
                    text: 'Activer tous les motifs',
                    value: 'enable-reasons',
                },
            ];
        }

        get headers() {
            return [
                {
                    text: 'Nom',
                    value: 'name',
                    sortable: false,
                    class: 'col-3 name',
                },
                {
                    text: 'Durée',
                    value: 'duration',
                    sortable: false,
                    class: 'col-2',
                },
                {
                    text: 'Délais min',
                    value: 'Délais.min',
                    sortable: false,
                    class: 'col-2 delais',
                },
                {
                    text: 'Délais max',
                    value: 'interval.max',
                    sortable: false,
                    class: 'col-2 delais',
                },
                {
                    text: 'Couleur',
                    value: 'color',
                    sortable: false,
                    class: 'col-1',
                },
                {
                    text: 'Notifications',
                    value: 'has_notifications',
                    sortable: false,
                    class: 'col-1',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    class: 'col-1',
                },
            ];
        }

        @Watch('search')
        public onChangeSearch(newVal: string) {
            this.debouncedSearchQuery();
        }

        public updatePerPageEnabled(e: number) {
            this.perPageEnabled = e;
            this.fetchReasonsList();
        }

        public updatePageEnabled(e: number) {
            this.pageEnabled = e;
            this.fetchReasonsList();
        }

        public updatePerPageDisabled(e: number) {
            this.perPageDisabled = e;
            this.fetchReasonsList();
        }

        public updatePageDisabled(e: number) {
            this.pageDisabled = e;
            this.fetchReasonsList();
        }

        public fetchReasonsList() {
            this.isFetched = true;

            const paramsEnabled: ISearchParams = {
                page: this.pageEnabled,
                per_page: this.perPageEnabled,
                organization_id: this.loggedOrganization.id,
                search: this.search,
            };

            const paramsDisabled: ISearchParams = {
                page: this.pageDisabled,
                per_page: this.perPageDisabled,
                organization_id: this.loggedOrganization.id,
                search: this.search,
            };

            const reasonsEnabledPromise = getModule(ReasonModule, this.$store)
                .fetchOrganizationReasonsEnabled(paramsEnabled);

            const reasonsDisabledPromise = getModule(ReasonModule, this.$store)
                .fetchOrganizationReasonsDisabled(paramsDisabled);

            return Promise.all([reasonsEnabledPromise, reasonsDisabledPromise]).then(() => {
                this.isFetched = false;
            });
        }

        public toggleItemNotifications(item: IReason) {
            item.send_sms = !item.send_sms;
            item.send_email = !item.send_email;
        }

        public creaded() {
            this.editDialog = false;
            this.fetchReasonsList();
        }

        public createReason() {
            (this.reasonToEdit as any) = {
                duration: 30,
                interval: {
                  min: 3_600_000,
                  max: 7_776_000_000,
                },
                color: '#63a612',
                send_email: true,
                send_sms: true,
                is_private: true,
                status:'enable',
            };

            this.editDialog = true;
        }

        public deleteReason(reason: IReason) {
            this.reasonToDelete = reason;
            this.confirmDeleteDialog = true;
        }

        public refreshReasons() {
            this.fetchReasonsList().then(() => {
                this.sendingToggleAction = false;
            });
        }

        public toggleDialogConfirm() {
            const selectedOption = this.toggleDialogType === 'reasonsEnabled' ?
                this.toggleAdvancedOptions :
                this.toggleAdvancedOptionsDisabled
            ;

            switch (selectedOption) {
                case 'disable-notifications':
                case 'enable-notifications':
                    const enabled = this.toggleAdvancedOptions === 'enable-notifications';

                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < this.innerReasonsEnabled.length; ++i) {
                        this.innerReasonsEnabled[i].send_email = enabled;
                        this.innerReasonsEnabled[i].send_sms = enabled;
                    }

                    this.hideToggleDialog();
                    break;

                case 'disable-reasons':
                case 'enable-reasons':
                    this.sendingToggleAction = true;

                    const status = selectedOption === 'disable-reasons' ? 'disabled' : 'enabled';
                    const reasons = (selectedOption === 'disable-reasons' ?
                        this.innerReasonsEnabled :
                        this.innerReasonsDisabled)
                        .map((reason: IReason) => {
                            if (selectedOption === 'enable-reasons') {
                                reason.send_sms = true;
                                reason.send_email = true;
                            }

                            reason.status = status;

                            return reason;
                        })
                    ;

                    this.bulkUpdateReasons({
                        reasons,
                        organization_id: this.loggedOrganization.id,
                    }).then(() => {
                        this.fetchReasonsList().then(() => {
                            this.sendingToggleAction = false;
                            this.hideToggleDialog();
                        });
                    });
                    break;
            }
        }

        public hideToggleDialog() {
          this.toggleDialog = false;
          this.toggleAdvancedOptions = null;
          this.toggleAdvancedOptionsDisabled = null;
        }

        public saveEnabledReasons() {
            this.isSavingReasonsEnabled = true;

            const reasons = this.innerReasonsEnabled.map((el: IReason) => {
                el.duration = Number(el.duration).valueOf();
                return el;
            });

            const parms: any = {
                reasons,
                status: 'enabled',
                options: this.toggleAdvancedOptions,
                organization_id: this.loggedOrganization.id,
            };

            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.bulkUpdateReasons(parms).then((e: any) => {
                this.isSavingReasonsEnabled = false;
                this.fetchReasonsList();
                snackbarModule.displaySuccess('Vos modifications ont bien été prises en compte !');
            });
        }

        public toggleReasonOnce(reason: IReason, target: string) {
            const parms: any = {
                reason_id: reason.id,
                status: target,
                organization_id: this.loggedOrganization.id,
            };

            if (reason.is_private) {
                this.updatePrivateReason(parms).then(()=> {
                    this.fetchReasonsList();
                });
            } else {
                this.updatePublicReason(parms).then(() => {
                    this.fetchReasonsList();
                });
            }
        }

        public handleEnabledReasonsAdvancedOption() {
            const index = ['disable-reasons', 'disable-notifications', 'enable-notifications']
                .indexOf(this.toggleAdvancedOptions as string)
            ;

            if (index === -1) {
                return;
            }

            this.alertMessage = this.tabMessages[index];
            this.toggleDialogType = 'reasonsEnabled';
            this.toggleDialog = true;
        }

        public handleDisabledReasonsAdvancedOption() {
            if (this.toggleAdvancedOptionsDisabled === 'enable-reasons') {
                this.alertMessage = this.tabMessages[3];
                this.toggleDialogType = 'reasonsDisabled';
                this.toggleDialog = true;
            }
        }

        public sendDeleteReasonRequest() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.sendingDeleteReasonRequest = true;

            getModule(ReasonModule, this.$store)
                .deleteReason({
                    organizationId: this.loggedOrganization.id,
                    reasonId: (this.reasonToDelete as IReason).id,
                })
                .then(() => {
                    this.confirmDeleteDialog = false;
                    this.reasonToDelete = null;
                    snackbarModule.displaySuccess('Votre motif a bien été supprimé !');
                    this.fetchReasonsList();
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => {
                    this.sendingDeleteReasonRequest = false;
                    this.fetchReasonsList();
                })
            ;
        }

        public mounted() {
            this.fetchReasonsList();
        }

        @Watch('organizationReasonsEnabled', {immediate: true})
        private organizationReasonsEnabledList(newVal: IReason[]) {
            const strReasonsEnabled = JSON.stringify(newVal);
            this.innerReasonsEnabled = newVal ? JSON.parse(strReasonsEnabled) : null;
            this.beforeReasonsEnabled = strReasonsEnabled;
        }

        @Watch('organizationReasonsDisabled', {immediate: true})
        private organizationReasonsDisabledList(newVal: IReason[]) {
            this.innerReasonsDisabled = newVal ? JSON.parse(JSON.stringify(newVal)) : null;
        }

        private reasonCreated() {
            this.fetchReasonsList();
            this.editDialog = false;
        }
    }
