
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IAccount,
        IAccountRole,
        IOrganization,
        IParaveterinarian,
    } from '@/types';

    import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
    import ParaveterinarianFormCard from '@/components/ParaveterinarianFormCard.vue';

    import {
        SnackbarModule,
        OrganizationModule,
        ParaveterinarianModule,
    } from '@/store/modules';

    import {
        formHelper,
    } from '@/helpers';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

    @Component<ParaveterinarianUsers>({
        components: {
            ConfirmDialog,
            ParaveterinarianFormCard,
        },
    })
    export default class ParaveterinarianUsers extends Vue {
        public isLoading: boolean = true;

        public createDialog: boolean = false;
        public confirmDeleteDialog: boolean = false;
        public inviteDialog: boolean = false;
        public editDialog: boolean = false;

        public sendingDeleteParaveterinarianRequest: boolean = false;
        public sendingInviteParaveterinarianRequest: boolean = false;
        public paraveterinarianToDelete: IParaveterinarian|null = null;
        public paraveterinarianToEdit: IParaveterinarian|null = null;
        public paraveterinarianToInvite: IParaveterinarian|null = null;

        public inviteParaveterinarianForm: boolean = false;
        public paraveterinarianToInviteEmail: string|null = null;

        public paraveterinarianEmailRules = formHelper.getEmailsRules();

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        @organizationNamespace.Getter('paraveterinariansList')
        public paraveterinariansList!: IParaveterinarian[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get headers() {
            return [
                {
                    text: 'Nom',
                    value: 'full_name',
                },
                {
                    text: 'Email',
                    value: 'email',
                },
                {
                    text: 'Statut',
                    value: 'role',
                },
                {
                    text: 'Actions',
                    value: 'action',
                    align: 'center',
                    sortable: false,
                },
            ];
        }

        public canDeleteUser(paraveterinarian: IParaveterinarian) {
            const role = paraveterinarian.role;
            const loggedRole = this.loggedAccountRoles[0].role.name;

            return (role !== 'organization_admin' && role !== 'organization_owner') || this.$hasRole('owner') || (loggedRole === 'organization_owner' && role !== 'organization_admin');
        }

        public canDowngradeUser(paraveterinarian: IParaveterinarian) {
            return paraveterinarian.has_account && paraveterinarian.role !== 'organization_admin' && paraveterinarian.role !== 'organization_member' && paraveterinarian.role !== 'organization_owner' || (this.$hasRole('owner') && paraveterinarian.role !== 'organization_member' && paraveterinarian.has_account);
        }

        public canPromoteUser(paraveterinarian: IParaveterinarian) {
            return paraveterinarian.role === 'organization_member' && this.$hasRole('owner');
        }

        public editParaveterinarian(paraveterinarian: IParaveterinarian) {
            this.paraveterinarianToEdit = Object.assign({}, paraveterinarian);
            this.editDialog = true;
        }

        public deleteParaveterinarian(paraveterinarian: IParaveterinarian) {
            this.paraveterinarianToDelete = paraveterinarian;
            this.confirmDeleteDialog = true;
        }

        public inviteParaveterinarian(paraveterinarian: IParaveterinarian) {
            this.paraveterinarianToInvite = paraveterinarian;
            this.paraveterinarianToInviteEmail = null;
            this.inviteDialog = true;
        }

        public paraveterinarianCreated() {
            this.createDialog = false;
            getModule(OrganizationModule, this.$store)
                .fetchOrganizationParaveterinariansList(this.loggedOrganization.id)
            ;
        }

        public sendDeleteParaveterinarianRequest() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.sendingDeleteParaveterinarianRequest = true;

            if (this.paraveterinarianToDelete) {
                getModule(OrganizationModule, this.$store)
                    .deleteOrganizationParaveterinarian({
                        paraveterinarian_id: this.paraveterinarianToDelete.id,
                        organization_id: this.loggedOrganization.id,
                    })
                    .then(() => {
                        this.confirmDeleteDialog = false;
                        this.paraveterinarianToDelete = null;

                        getModule(OrganizationModule, this.$store)
                            .fetchOrganizationParaveterinariansList(this.loggedOrganization.id)
                            .then(() => snackbarModule.displaySuccess('L\'utilisateur a bien été supprimé !'))
                        ;
                    })
                    .catch(() => snackbarModule.displayError())
                    .finally(() => this.sendingDeleteParaveterinarianRequest = false)
                ;
            }
        }

        public downgradeParaveterinarian(paraveterinarian: IParaveterinarian) {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            getModule(ParaveterinarianModule, this.$store)
                .changeParaveterinarianRole({
                    paraveterinarian_id: paraveterinarian.id,
                    organization_id: this.loggedOrganization.id,
                    role: 'organization_member',
                })
                .then(() => snackbarModule.displaySuccess('L\'utilisateur a bien été rétrogradé !'))
                .catch(() => snackbarModule.displayError())
            ;
        }

        public promoteParaveterinarian(paraveterinarian: IParaveterinarian) {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            getModule(ParaveterinarianModule, this.$store)
                .changeParaveterinarianRole({
                    paraveterinarian_id: paraveterinarian.id,
                    organization_id: this.loggedOrganization.id,
                    role: 'organization_admin',
                })
                .then(() => snackbarModule.displaySuccess('L\'utilisateur a bien été promu administrateur !'))
                .catch(() => snackbarModule.displayError())
            ;
        }

        public sendInviteParaveterinarianRequest() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.sendingInviteParaveterinarianRequest = true;

            if (this.paraveterinarianToInvite) {
                getModule(ParaveterinarianModule, this.$store)
                    .inviteParaveterinarian({
                        paraveterinarian_id: this.paraveterinarianToInvite.id,
                        organization_id: this.loggedOrganization.id,
                        email: this.paraveterinarianToInviteEmail as string,
                    })
                    .then(() => {
                        this.inviteDialog = false;
                        this.paraveterinarianToInvite = null;

                        snackbarModule.displaySuccess('L\'invitation a bien été envoyée !');
                    })
                    .catch(() => snackbarModule.displayError())
                    .finally(() => this.sendingInviteParaveterinarianRequest = false)
                ;
            }
        }

        public created() {
            getModule(OrganizationModule, this.$store)
                .fetchOrganizationParaveterinariansList(this.loggedOrganization.id)
                .then(() => this.isLoading = false)
            ;
        }
    }
