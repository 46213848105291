
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import {Model, Prop} from 'vue-property-decorator';
  import {Duration} from 'luxon';
import { IRule } from '@/types';

  @Component<DurationInput>({})
  export default class DurationInput extends Vue {
    @Model('input', {type: Number}) public readonly value!: number|null;

    @Prop({
      type: Array,
      required: false,
    })
    public rules!: IRule[];

    private duration: number|null = null;
    private durationValue: number|null = null;
    private durationUnit: string|null = null;

    private units = [
      {value: 'minutes', text: 'minute(s)'},
      {value: 'hours', text: 'heure(s)'},
      {value: 'days', text: 'jour(s)'},
    ];

    private onInputChange() {
      if (!this.durationUnit || !this.durationValue) {
        this.duration = null;
        this.$emit('input', null);
        return;
      }

      this.duration = Duration.fromObject({[this.durationUnit as string]: this.durationValue}).toMillis();
      this.$emit('input', this.duration);
    }

    private updateFields() {
      if (!this.value) {
        this.durationValue = null;
        this.durationUnit = null;

        return;
      }

      const duration = Duration.fromMillis(this.value);
      const minutes = duration.as('minutes');
      const hours = duration.as('hours');
      const days = duration.as('days');

      if (days % 1 === 0) {
        this.durationValue = days;
        this.durationUnit = 'days';
      } else if (hours % 1 === 0) {
        this.durationValue = hours;
        this.durationUnit = 'hours';
      } else if (minutes % 1 === 0) {
        this.durationValue = minutes;
        this.durationUnit = 'minutes';
      }

      this.duration = this.value;
    }

    private mounted() {
      this.updateFields();
    }
  }
