
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';
    import {formHelper} from '@/helpers';

    import {
        IOrganization,
        IPostOrganizationSocialsParams,
    } from '@/types';

    import {
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');

    @Component<OrganizationProfileSocial>({})
    export default class OrganizationProfileSocial extends Vue {
        public formIsChanged: boolean = false;
        public isSubmittingSocials: boolean = false;
        public thumbnailCreated: boolean = false;
        public formValid: boolean = false;

        public innerModel!: IOrganization;

        public webUrlRules = formHelper.getOrganizationWebUrlRules();
        public facebookUrlRules = formHelper.getOrganizationFacebookUrlRules();
        public twitterUrlRules = formHelper.getOrganizationTwitterUrlRules();
        public instagramUrlRules = formHelper.getOrganizationInstagramUrlRules();

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        public submitSocials() {
            this.isSubmittingSocials = true;

            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            Object.keys(this.innerModel.social_links).map((key: string) => {
                (this.innerModel.social_links as any)[key] =
                   (this.innerModel.social_links as any)[key] ?  (this.innerModel.social_links as any)[key] : null;
            });

            const params: IPostOrganizationSocialsParams = {
                organization_id: this.loggedOrganization.id,
                social_links: this.innerModel.social_links,
            };

            if (params.social_links.facebook_link) {
                if (params.social_links.facebook_link[params.social_links.facebook_link.length - 1] === '/') {
                    params.social_links.facebook_link = params.social_links.facebook_link.slice(0, -1);
                }
            }

            organizationModule
                .postSocials(params)
                .then(() => {
                    snackbarModule.displaySuccess(
                        `Les liens web et réseaux sociaux de ${this.loggedOrganization.name} ont bien été modifiés !`,
                    );
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
                .finally(() => {
                    this.isSubmittingSocials = false;
                    this.formIsChanged = false;
                })
            ;
        }

        private data() {
            return {
                innerModel: this.loggedOrganization ? JSON.parse(JSON.stringify(this.loggedOrganization)) : null,
            };
        }

        @Watch('loggedOrganization', {immediate: true})
        private onLoggedOrganizationChange(newVal: IOrganization) {
            this.innerModel = newVal ? JSON.parse(JSON.stringify(newVal)) : null;
        }
    }
