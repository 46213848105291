
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';

    import {
        IOrganization,
        IUser,
    } from '@/types';

    import {SnackbarModule} from '@/store/modules';

    import VetupSynchronization from '@/components/synchronization/VetupSynchronization.vue';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

    @Component<OrganizationSynchronization>({
        components: {
            VetupSynchronization,
        },
    })
    export default class OrganizationSynchronization extends Vue {
        public token: string|null = null;
        public type: string|null = null;
        public loading: boolean = false;
        public submittingGenerateToken: boolean = false;
        public submittingDeleteToken: boolean = false;

        public synchronizationFormValid: boolean = false;
        public pms: string|null = null;
        public assistovetSoftware: string|null = null;
        public assistovetVersion: string|null = null;
        public omnisVersion: string|null = null;
        public pmsVersion: string|null = null;
        public message: string|null = null;

        public pmsRules = [
            (v: any) => !!v || 'Veuillez renseigner votre logiciel metier',
        ];

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        public assistovetSoftwares = [
            'Maestro',
            'Sublimo',
        ];

        public supportedPMS = [
            {
                text: 'Assistovet',
                value: 'assistovet',
            },
            {
                text: 'Bourgelat',
                value: 'bourgelat',
            },
            {
                text: 'Vetocom',
                value: 'vetocom',
            },
            {
                text: 'Vetup',
                value: 'vetup',
            },
            /*
            {
                text: 'Epivet',
                value: 'epivet',
            },
            {
                text: 'Gmvet',
                value: 'gmvet',
            },
            {
                text: 'Vet\'Phi',
                value: 'vetphi',
            },
            {
                text: 'Vikee',
                value: 'vikee',
            },*/
        ];

        public get pmsList() {
            const pmsList = [...this.supportedPMS];

            pmsList.push({
                text: 'Autre',
                value: 'other',
            });

            return pmsList;
        }

        public get synchronizationFormParams() {
            const params: any = {
                postal_code: this.loggedOrganization.address.postal_code,
                organization_name: this.loggedOrganization.name,
                last_name: this.loggedUser.veterinarian?.last_name ?? this.loggedUser.paraveterinarian?.last_name,
                phone: this.loggedUser.veterinarian?.cell_phone ?? this.loggedUser.paraveterinarian?.cell_phone,
                description: this.message,
                organization_id: this.loggedOrganization.id,
                pms: this.pms,
                subject: 'synchronization',
                type: 'contact',
            };

            if (this.pms === 'assistovet') {
                params.assistovet_software = this.assistovetSoftware;
                params.assistovet_version = this.assistovetVersion;
                params.omnis_version = this.omnisVersion;
            } else {
                params.pms_version = this.pmsVersion;
            }

            return params;
        }

        public getTypeText(type: string) {
            const entry = this.supportedPMS.find((value: any) => value.value === type);

            if (entry) {
                return entry.text;
            }

            return null;
        }

        public copyToken() {
            const textArea = document.createElement('textarea');

            textArea.classList.add('copy-textarea');
            textArea.textContent = this.token;
            document.body.append(textArea);
            textArea.select();
            textArea.setSelectionRange(0, 99999); /* For mobile devices */
            document.execCommand('copy');
            textArea.remove();

            getModule(SnackbarModule, this.$store).displaySuccess('Votre jeton a bien été copié');
        }

        public deleteToken() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.submittingDeleteToken = true;

            this.$api
                .organization
                .deleteSyncToken(this.loggedOrganization.id)
                .then(() => {
                    this.token = null;
                    this.type = null;

                    snackbarModule.displaySuccess(
                        `Votre synchronisation a bien été supprimée sur Vetolib !<br>
                        Si vous l'aviez déjà mise en place sur votre PMS, veuillez la supprimer également dessus`,
                    );
                })
                .finally(() => this.submittingDeleteToken = false)
            ;
        }

        public generateToken() {
            this.submittingGenerateToken = true;

            this.$api
                .organization
                .generateSyncToken(this.loggedOrganization.id, this.type as string)
                .then((token: string) => this.token = token)
                .finally(() => this.submittingGenerateToken = false)
            ;
        }

        public sendSynchronizationForm() {
            this.loading = true;
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.$api.contact
                .sendMessage(this.synchronizationFormParams)
                .then(() => {
                    this.resetForm();
                    snackbarModule.displaySuccess(
                        `Votre message a bien été envoyé.
                        Nous reviendrons vers vous dans les plus brefs délais !`,
                    );
                })
                .catch(() => {
                    snackbarModule.displayError(
                        `Une erreur est survenue pendant l'envoi de votre message.
                        Veuillez réessayer ultérieurement.`,
                    );
                })
                .finally(() => this.loading = false)
            ;
        }

        private resetForm() {
            this.message = null;
            this.assistovetVersion = null;
            this.omnisVersion = null;

            (this.$refs.synchronizationForm as HTMLFormElement).resetValidation();
        }

        private created() {
            this.loading = true;

            this.$api
                .organization
                .getSyncToken(this.loggedOrganization.id)
                .then((response: any) => {
                    this.token = response.token;
                    this.type = response.type;
                })
                .finally(() => this.loading = false)
            ;
        }
    }
