
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';
    import OrganizationInstruction from '@/components/OrganizationInstruction.vue';
    import InstructionFormCard from '@/components/forms/InstructionFormCard.vue';
    import {debounce} from 'debounce';
    import {Route, NavigationGuardNext} from 'vue-router';

    import {
        IOrganization,
        ISearchInstructionsParams,
        IOrganizationInstruction,
        IUpdateOrganizationInstructionParams,
        IDeleteOrganizationInstructionParams,
    } from '@/types';

    import {
        OrganizationModule,
        InstructionModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');
    const instructionNamespace = namespace('instruction');

    @Component<OrganizationInstructions>({
        components: {
            InstructionFormCard,
            OrganizationInstruction,
        },
        beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
            const self: OrganizationInstructions = this;

            if (self.hasChanged) {
                self.redirectTo = to;
                self.showAlert = true;
                self.hasChanged = false;
                next(false);
            } else {
                next();
            }
        },
    })
    export default class OrganizationInstructions extends Vue {
        public instructionsIsFetched: boolean = false;
        public editDialog: boolean = false;
        public pageEnabled: number = 1;
        public perPageEnabled: number = 10;
        public pageDisabled: number = 1;
        public perPageDisabled: number = 10;
        public search: string = '';
        public expanded = [];

        public hasChanged: boolean = false;
        public redirectTo: Route|null = null;
        public showAlert: boolean = false;

        public tabs: string = '';

        public itemsNav = [
            {
                title: 'Actif',
                value: 'activ',
            },
            {
                title: 'Inactif',
                value: 'inactiv',
            },
        ];

        public navItems: string[] = [
          'Motifs actifs',
          'Motifs inactifs',
        ];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @instructionNamespace.Getter('enabledInstructionsList')
        public instructionsEnabled!: IOrganizationInstruction;

        @instructionNamespace.Getter('getEnabledInstructionsTotal')
        public instructionsTotalEnabled!: number;

        @instructionNamespace.Getter('disabledInstructionsList')
        public instructionsDisabled!: IOrganizationInstruction;

        @instructionNamespace.Getter('getDisabledInstructionsTotal')
        public instructionsTotalDisabled!: number;

        private debouncedSearchQuery = debounce(this.fetchAll, 300);

        public updatePerPageEnabled(e: number) {
            this.perPageEnabled = e;
            this.fetchAll();
        }

        public updatePageEnabled(e: number) {
            this.pageEnabled = e;
            this.fetchAll();
        }

        public updatePerPageDisabled(e: number) {
            this.perPageDisabled = e;
            this.fetchAll();
        }

        public updatePageDisabled(e: number) {
            this.pageDisabled = e;
            this.fetchAll();
        }

        get headers() {
            return [
                {
                    text: 'Titre',
                    value: 'message_type',
                    class: 'col-5',
                    sortable: false,
                },
                {
                    text: 'Motif(s)',
                    value: 'reasons',
                    class: 'col-2',
                    sortable: false,
                },
                {
                    text: 'Agenda(s)',
                    value: 'agendas',
                    class: 'col-1',
                    sortable: false,
                },
                {
                    text: 'Apparition',
                    value: 'before_after',
                    class: 'col-2',
                    sortable: false,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    class: 'col-2',
                    sortable: false,
                },
            ];
        }

        get headersDisabled() {
            return JSON.parse(JSON.stringify(this.headers));
        }

        public getFormattedInstruction(instruction: string, variables: any) {
            return instruction
                .split(':?')
                .map((part: string) => variables[part] ?? part)
                .join('')
            ;
        }

        public closeAll() {
            this.hasChanged = false;
            this.fetchAll();
        }

        public toggleActive(target: string, item: IOrganizationInstruction) {
            this.instructionsIsFetched = false;

            const params: IUpdateOrganizationInstructionParams = {
                id: item.id as string,
                organization_id: this.loggedOrganization.id,
                status: target,
            };

            getModule(InstructionModule, this.$store).updateInstruction(params)
                .then(() => {
                    this.fetchAll();
                },
            );
        }

        public deleteInstruction(item: IOrganizationInstruction) {
            this.instructionsIsFetched = false;

            const params: IDeleteOrganizationInstructionParams = {
                id: item.id as string,
                organization_id: this.loggedOrganization.id as string,
            };

            getModule(InstructionModule, this.$store).deleteInstruction(params)
                .then(() => {
                    this.fetchAll();
                },
            );
        }

        public innerInstructionChanged(val: boolean) {
            this.hasChanged = val;
            return this.hasChanged;
        }

        public redirected(bol: boolean) {
            if (!bol) {
                this.showAlert = false;
                this.redirectTo = null;
                this.hasChanged = true;
            }

            if (this.redirectTo) {
               this.$router.push({path:this.redirectTo.path});
            }
        }

        public createInstruction() {
            this.editDialog = false;
            this.fetchAll();
        }

        public showCreateInstruction() {
            this.editDialog = !this.editDialog;
        }

        public refreshReasons() {
            this.fetchAll();
        }

        public fetchAll() {
            this.expanded = [];
            this.instructionsIsFetched = false;

            const paramsEnabled: ISearchInstructionsParams = {
                page: this.pageEnabled,
                page_size: this.perPageEnabled,
                organization_id: this.loggedOrganization.id,
                title: this.search,
                status: 'enabled',
            };

            const paramsDisabled: ISearchInstructionsParams = {
                page: this.pageDisabled,
                page_size: this.perPageDisabled,
                organization_id: this.loggedOrganization.id,
                title: this.search,
                status: 'disabled',
            };

            const instructionsEnabled = getModule(InstructionModule, this.$store)
            .fetchInstructionsEnabled(paramsEnabled);

            const instructionsDisabled = getModule(InstructionModule, this.$store)
            .fetchInstructionsDisabled(paramsDisabled);

            return Promise.all([instructionsEnabled, instructionsDisabled])
                .then(() => {
                    this.instructionsIsFetched = true;
                },
            );
        }

        public mounted() {
            const organizationModule = getModule(OrganizationModule, this.$store);

            organizationModule.fetchOrganization(this.loggedOrganization.id)
                .then(() => {
                    this.fetchAll();
                },
            );
        }

        @Watch('search')
        public onChangeSearch(newVal: string) {
            this.debouncedSearchQuery();
        }
    }
