
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch,Prop} from 'vue-property-decorator';
    import {imageHelper} from '@/helpers';

    import {
      IOrganization,
      IAgenda,
      IOrganizationInstruction,
      IUpdateOrganizationInstructionParams,
      IPostInstructionPicturesParams,
      IDeleteInstructionPictureParams,
      IPostInstructionPicturesResult,
      IPostInstructionPicturesError,
      ISearchParams,
      IReason,
      IInstructionVariables,
    } from '@/types';

    import {
        AgendaModule,
        ReasonModule,
        InstructionModule,
        SnackbarModule,
    } from '@/store/modules';

    const agendaNamespace = namespace('agenda');
    const organizationNamespace = namespace('organization');
    const reasonNamespace = namespace('reason');

    @Component<OrganizationInstruction>({})

    export default class OrganizationInstruction extends Vue {

        public isLoadingAgendas: boolean = true;
        public isLoadingReasons: boolean = true;
        public validForm: boolean = false;
        public isLoading: boolean = false;
        public isDeletingPicture: boolean = false;
        public isChanged: boolean = false;

        public innerModel!: IOrganizationInstruction;
        public unchangedInstructions: string|null = null;

        public files: File[] = [];
        public resizeImg: any = [];
        public isUploadingPictures: boolean = false;

        @Prop({
            type: Object,
            required: true,
        })
        public instruction!: IOrganizationInstruction;

        @agendaNamespace.Getter('agendasList')
        public agendasList!: IAgenda[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @reasonNamespace.Getter('organizationReasonsEnabledList')
        public organizationReasonsEnabledList!: IReason[];

        public titleRules = [
            (v: string) => !!v || 'Le nom est requis',
        ];

        public areEmpty = [
            this.validateInstructionPresence,
        ];

        public validateInstructionPresence() {

            return !!this.innerModel.instruction.post_booking_instruction ||
                    !!this.innerModel.instruction.pre_booking_instruction ||
                    'Veuillez renseigner au moins une consigne (avant ou après rendez-vous)';
        }

        public revalidateForm() {
          this.$nextTick(() => {
              (this.$refs.instructionForm as HTMLFormElement).validate();
          });
        }

        get renderSentence() {
          if (!this.innerModel.used_variables) {
            return;
          }

          const postbook = this.innerModel.instruction.post_booking_instruction;

          for (const key in this.innerModel.used_variables) {
            if(this.innerModel.used_variables.hasOwnProperty(key)) {
              const strSplit = postbook.split(':?');
              // tslint:disable-next-line:max-line-length
              const items = this.innerModel.instruction.variables?.find((elm: IInstructionVariables) => elm.name === key);

              return strSplit.map((elm: string) => {
                if(elm === key) {
                  return {
                    type: items?.type,
                    values: items?.values,
                    model: this.innerModel.used_variables[key],
                    name: items?.name,
                  };
                } else {
                  return {
                    type: 'string',
                    value: elm,
                  };
                }
              });
            }
          }
        }

        public updateInstruction() {
          this.isLoading = true;
          let sentence: string = '';
          let params = {};
          const forVariables: IInstructionVariables[] = [];
          const forPassedVariables: object[] = [];

          const agendas = this.innerModel.agendas.map((agenda: IAgenda) => agenda.id);
          const reasons = this.innerModel.reasons.map((reason: IReason) => reason.id);

          if(Object.keys(this.innerModel.used_variables).length > 0) {

            this.renderSentence?.forEach((elm, i) => {
              if(elm.type === 'string') {
                sentence = sentence+elm.value;
              } else {
                sentence = sentence+':?'+elm.name+':?';

                const toVarElm = {
                  name: elm.name,
                  type: elm.type,
                  values: elm.values,
                };

                forVariables.push(toVarElm);
                forPassedVariables.push({[elm.name as string]: elm.model});
              }
            });

            params = {
              id: this.innerModel.id,
              reasons,
              agendas,
              organization_id: this.loggedOrganization.id,
              passed_variables: forPassedVariables,
              organization_instruction_files: this.innerModel.organization_instruction_files as object,
            };

          } else {
            params = {
              id: this.innerModel.id,
              reasons,
              agendas,
              passed_variables: [],
              organization_id: this.loggedOrganization.id,
              instruction: {
                title: this.innerModel.instruction.title,
                pre_booking_instruction: this.innerModel.instruction.pre_booking_instruction,
                post_booking_instruction: this.innerModel.instruction.post_booking_instruction,
                variables: [],
              },
              organization_instruction_files: this.innerModel.organization_instruction_files as object,
            };
          }

          const promiseInstructions = getModule(InstructionModule, this.$store).updateInstruction(params as IUpdateOrganizationInstructionParams);
          const promiseUploadPicture = this.sendUploadPicturesRequest(this.innerModel);

          Promise.all([promiseInstructions, promiseUploadPicture])
            .then(() => {
                this.$emit('updated');
                this.isLoading = false;
            },
          );
        }

        public uploadFile(pictures: File[]) {
            if (!pictures) {
              return;
            }

            this.isUploadingPictures = true;
            this.files = pictures;

            this.resizeImg = Promise.all(
              this.files.map((file: File) => {
                if(file.type.match(/image.*/)) {
                  return imageHelper.resizeImage({
                      maxWidth: 1920,
                      maxHeight: 1080,
                      file,
                  });
                } else {
                  return file;
                }
              }),
            );
        }

        public sendUploadPicturesRequest(instruction: IOrganizationInstruction) {
          return new Promise<IPostInstructionPicturesResult>((resolve, reject) => {
            if (this.files.length === 0) {
              return resolve({uploaded: [], errors: []});
            }

            const instructionModule = getModule(InstructionModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);
            const formData = new FormData();

            formData.append('organization_instruction_id', instruction.id as string);

            this.resizeImg.then((files: File[]) => {
              files.map((file, index) => {
                  formData.append(`files[${index}]`, file);
              });


              const params: IPostInstructionPicturesParams = {
                  organization_id: this.loggedOrganization.id,
                  files: formData,
              };

              instructionModule
                  .postPictures(params)
                  .then((response: IPostInstructionPicturesResult) => {
                      if (response.errors.length > 0) {
                        const failedFiles =
                            response.errors.map((value: IPostInstructionPicturesError) => {
                                return value.file;
                            })
                                .join(', ')
                        ;
                        snackbarModule.displayError(`Une erreur est survenue pendant l'envoi des images suivantes : ${failedFiles}`);
                      } else {
                        snackbarModule.displaySuccess('Vos images ont bien été ajoutées !');
                        resolve(response);
                      }
                  })
                  .catch((error) => {
                    snackbarModule.displayError();
                    reject(error);
                  })
                  .finally(() => {
                      this.isUploadingPictures = false;
                      this.files = [];
                  })
              ;
              });
            });

        }

        public deleteFile(pictureId: string) {
          this.isDeletingPicture = true;

          const params: IDeleteInstructionPictureParams = {
            picture_id: pictureId,
            organization_id: this.loggedOrganization.id,
          };

           getModule(InstructionModule, this.$store).deletePicture(params)
            .then(() => {
                this.isDeletingPicture = false;
            },
          );
        }

        public mounted() {
            const agendaModule = getModule(AgendaModule, this.$store);
            const reasonModule = getModule(ReasonModule, this.$store);

            agendaModule.fetchAgendas(this.loggedOrganization.id)
              .then(() => {
                this.isLoadingAgendas = false;
              },
            );

            const params: ISearchParams = {
              page: 1,
              per_page: 500,
              organization_id: this.loggedOrganization.id,
            };

            reasonModule.fetchOrganizationReasonsEnabled(params)
              .then(() => {
                this.isLoadingReasons = false;
              },
            );
        }

        public data() {
            return {
                innerModel: JSON.parse(JSON.stringify(this.instruction)),
            };
        }

        @Watch('instruction', {immediate: true, deep: true})
        private onInstructionChange(newVal: IOrganizationInstruction) {
            this.innerModel = newVal ? JSON.parse(JSON.stringify(newVal)) : null;
            this.unchangedInstructions = JSON.stringify(newVal);
        }

        @Watch('innerModel', {immediate: true, deep: true})
        private innerModelChanged(newVal: IOrganizationInstruction) {
          this.isChanged = this.unchangedInstructions !== JSON.stringify(this.innerModel);
          return this.$emit('changing', this.unchangedInstructions !== JSON.stringify(this.innerModel));
        }

    }
