
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Model} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        ICreateOrganizationParaveterinarianParams,
        IOrganization,
        IParaveterinarian,
    } from '@/types';

    import {
        ParaveterinarianModule,
        SnackbarModule,
    } from '@/store/modules';

    import {formHelper} from '@/helpers';

    const organizationNamespace = namespace('organization');

    @Component<ParaveterinarianFormCard>({})
    export default class ParaveterinarianFormCard extends Vue {
        public isSubmittingParaveterinarian: boolean = false;
        public paraveterinarianFormValid: boolean = false;

        public innerModel!: IParaveterinarian;
        public email: string|null = null;

        public paraveterinarianEmailRules = formHelper.getEmailsRules();
        public paraveterinarianFirstNameRules = formHelper.getParaveterinarianFirstNameRules();
        public paraveterinarianLastNameRules = formHelper.getParaveterinarianLastNameRules();

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization|null;

        @Model('input', {type: Object}) public readonly value!: IParaveterinarian;

        get params(): ICreateOrganizationParaveterinarianParams {
            const params: ICreateOrganizationParaveterinarianParams = {
                first_name: this.innerModel.first_name,
                last_name: this.innerModel.last_name,
                organization_id: (this.loggedOrganization as IOrganization).id,
            };

            if (this.email) {
                params.email = this.email;
            }

            return params;
        }

        public backButtonClicked() {
            return this.$emit('close');
        }

        public createParaveterinarian() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.isSubmittingParaveterinarian = true;

            getModule(ParaveterinarianModule, this.$store)
                .createParaveterinarian(this.params)
                .then((paraveterinarian: IParaveterinarian) => {
                    snackbarModule.displaySuccess(
                        `${paraveterinarian.first_name} ${paraveterinarian.last_name} a bien été créé(e) !`,
                    );

                    this.$emit('input', paraveterinarian);
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingParaveterinarian = false)
            ;
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.value),
            };
        }
    }
