
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IOrganization,
        ISpecialty,
        IVeterinarian,
    } from '@/types';

    import {
        OrganizationModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');
    const veterinarianNamespace = namespace('veterinarian');

    import AccountAvatar from '@/components/account-profile/AccountProfileAvatar.vue';
    import AccountPresentationVeterinarian from '@/components/account-profile/AccountProfilePresentationVeterinarian.vue';
    import AccountPresentationParaveterinarian from '@/components/account-profile/AccountProfilePresentationParaveterinarian.vue';
    import AccountPictures from '@/components/account-profile/AccountProfilePictures.vue';
    import AccountEducation from '@/components/account-profile/AccountProfileEducation.vue';

    @Component<VeterinarianFullFormCard>({
        components: {
            AccountAvatar,
            AccountPresentationVeterinarian,
            AccountPresentationParaveterinarian,
            AccountPictures,
            AccountEducation,
        },
    })
    export default class VeterinarianFullFormCard extends Vue {
        @veterinarianNamespace.Getter('specialtiesList')
        public specialtiesList!: ISpecialty[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization|null;

        @organizationNamespace.Getter('veterinarian')
        public organizationVeterinarian!: IVeterinarian|null;

        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian!: IVeterinarian;

        public mounted() {
            if (this.loggedOrganization) {
                getModule(OrganizationModule, this.$store)
                    .fetchOrganizationVeterinarian({
                        veterinarian_id: this.veterinarian.id,
                        organization_id: this.loggedOrganization.id,
                    });
            }
        }
    }
