
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IAccount,
        IOrganization,
        IPostOrganizationAvatarParams,
    } from '@/types';

    import {
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

    import EditAvatarDialog from '@/components/dialogs/EditAvatarDialog.vue';
    import ProfilePresentation from '@/components/organization-profile/OrganizationProfilePresentation.vue';

    @Component<OrganizationProfileInfos>({
        components: {
            EditAvatarDialog,
            ProfilePresentation,
        },
    })
    export default class OrganizationProfileInfos extends Vue {
        public isSubmittingAvatar: boolean = false;
        public isSubmittingReset: boolean = false;
        public isLoading: boolean = true;
        public avatarDialog: boolean = false;
        public presDialog: boolean = false;
        public fileToUpload: File|null = null;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        public get showResetButton() {
            return process.env.VUE_APP_ASSISTOVET_ACCOUNT === this.loggedAccount.id;
        }

        get payments() {
            if (!this.loggedOrganization) {
                return [];
            }

            if (!this.loggedOrganization.payments) {
                return [];
            }

            return (this.loggedOrganization.payments as string[]).map((payment) => {
                switch (payment) {
                    case 'american_express': return 'American Express';
                    case 'blue_card': return 'CB';
                    case 'cash': return 'Espèces';
                    case 'check': return 'Chèque';
                    case 'alma': return 'Alma';
                    case 'payvet': return 'Payvet';
                    default: return '';
                }
            });
        }

        public resetOrganization() {
            this.isSubmittingReset = true;

            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            organizationModule
                .resetOrganization(this.loggedOrganization.id)
                .then(() => {
                    snackbarModule.displaySuccess(`L'établissement a bien été réinitialisé !`);
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
                .finally(() => {
                    this.isSubmittingReset = false;
                })
            ;
        }

        public submitAvatar(blob: File) {
            this.isSubmittingAvatar = true;

            const formData = new FormData();
            formData.append('avatar', blob);

            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);
            const params: IPostOrganizationAvatarParams = {
                organization_id: this.loggedOrganization.id,
                avatar: formData,
            };

            organizationModule
                .postAvatar(params)
                .then(() => {
                    snackbarModule.displaySuccess(`L'avatar de ${this.loggedOrganization.name} a bien été modifié !`);
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
                .finally(() => {
                    this.isSubmittingAvatar = false;
                    this.avatarDialog = false;
                })
            ;
        }
    }
