
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IAgenda,
        IOrganization,
        IVeterinarian,
    } from '@/types';

    import CreateAgendaCard from '@/components/CreateAgendaCard.vue';
    import AgendaFormCard from '@/components/AgendaFormCard.vue';

    import {
        AgendaModule, AnimalModule,
        SnackbarModule,
    } from '@/store/modules';

    const agendaNamespace = namespace('agenda');
    const organizationNamespace = namespace('organization');

    @Component<OrganizationAgendas>({
        components: {
            CreateAgendaCard,
            AgendaFormCard,
        },
    })
    export default class OrganizationAgendas extends Vue {
        public isLoading: boolean = true;

        public createDialog: boolean = false;
        public confirmDeleteDialog: boolean = false;
        public editDialog: boolean = false;

        public sendingDeleteAgendaRequest: boolean = false;

        public agendaToDelete: IAgenda|null = null;
        public agendaToEdit: IAgenda|null = null;

        @agendaNamespace.Getter('agendasList')
        public agendasList!: IAgenda[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get headers() {
            return [
                {
                    text: `Titre de l'agenda`,
                    value: 'name',
                    sortable: false,
                },
                {
                    text: 'Praticien',
                    value: 'veterinarian',
                    sort: (a: IVeterinarian, b: IVeterinarian) => {
                        const aName = `${a.first_name} ${a.last_name}`;
                        const bName = `${b.first_name} ${b.last_name}`;

                        return aName > bName ? 1 : (bName > aName ? -1 : 0);
                    },
                },
                {
                    text: 'Groupes',
                    value: 'groups',
                    sortable: false,
                },
                {
                    text: 'Espèces',
                    value: 'species',
                    sortable: false,
                },
                {
                    text: 'Visible en ligne',
                    value: 'is_public',
                    sortable: false,
                },
                {
                    text: 'Prise de RDV directe autorisée',
                    value: 'is_directly_bookable',
                    sortable: false,
                },
                {
                    text: 'Email nouveau RDV',
                    value: 'new_booking_email',
                    sortable: false,
                },
                {
                    text: 'SMS nouveau RDV',
                    value: 'new_booking_sms',
                    sortable: false,
                },
                {
                    text: 'Actions',
                    value: 'action',
                    sortable: false,
                },
            ];
        }

        public editAgenda(agenda: IAgenda) {
            this.agendaToEdit = Object.assign({}, agenda);
            this.editDialog = true;
        }

        public deleteAgenda(agenda: IAgenda) {
            this.agendaToDelete = agenda;
            this.confirmDeleteDialog = true;
        }

        public sendDeleteAgendaRequest() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.sendingDeleteAgendaRequest = true;

            getModule(AgendaModule, this.$store)
                .deleteAgenda({
                    organizationId: this.loggedOrganization.id,
                    agendaId: (this.agendaToDelete as IAgenda).id as string,
                })
                .then(() => {
                    this.confirmDeleteDialog = false;
                    this.agendaToDelete = null;
                    snackbarModule.displaySuccess('Votre agenda a bien été supprimé !');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.sendingDeleteAgendaRequest = false)
            ;
        }

        public mounted() {
            getModule(AnimalModule, this.$store).computeSpeciesByTypeList();

            getModule(AgendaModule, this.$store)
                .fetchAgendas(this.loggedOrganization.id)
                .then(() => this.isLoading = false)
            ;
        }
    }
