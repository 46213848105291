
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IOrganization,
    } from '@/types';

    import {
        OrganizationModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');
    
    import ProfilePartner from '@/components/organization-profile/OrganizationProfilePartner.vue';
    import ProfilePictures from '@/components/organization-profile/OrganizationProfilePictures.vue';
    import ProfileSocial from '@/components/organization-profile/OrganizationProfileSocial.vue';
    import ProfileFees from '@/components/organization-profile/OrganizationProfileFees.vue';
    import ProfileOpeningHours from '@/components/organization-profile/OrganizationProfileOpeningHours.vue';
    import OrganizationInfos from '@/components/organization-profile/OrganizationProfileInfos.vue';

    @Component<OrganizationProfile>({
        components: {
            ProfilePartner,
            ProfilePictures,
            ProfileSocial,
            ProfileFees,
            ProfileOpeningHours,
            OrganizationInfos,
        },
    })
    export default class OrganizationProfile extends Vue {
        public organizationIsFetched = false;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get uri () {
            const orga: IOrganization = this.loggedOrganization;
            return 'https://www.vetolib.fr/'+orga.slug;
        }        

        public getHtmlAddress() {
            if (!this.loggedOrganization.address) {
                return '';
            }

            const postalCode = this.loggedOrganization.address.postal_code ? this.loggedOrganization.address.postal_code : '';
            const city = this.loggedOrganization.address.city ? this.loggedOrganization.address.city : '';

            const address = `${postalCode} ${city}`.trim();

            if (this.loggedOrganization.organization_type && this.loggedOrganization.organization_type.name === 'Vétérinaire à domicile') {
                return address;
            }


            const houseNumber = this.loggedOrganization.address.house_number ? this.loggedOrganization.address.house_number : '';
            const street = this.loggedOrganization.address.street ? this.loggedOrganization.address.street : '';

            return `${houseNumber} ${street}<br>${address}`.trim();
        }

        public mounted() {
            const organizationModule = getModule(OrganizationModule, this.$store);
            organizationModule
                .fetchOrganization(this.loggedOrganization.id)
                .then((organization: IOrganization) => {
                    this.organizationIsFetched = true;
                });
        }

        public scrollToHash() {
            const hash = this.$route.hash;
            this.$nextTick(() => {
                this.$scrollTo(hash, 0);
            });
        }

        public updated() {
            if (this.$route.hash && this.organizationIsFetched) {
                this.scrollToHash();
            }
        }
    }
