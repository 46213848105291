
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';
    import {imageHelper} from '@/helpers';
    import PictureDropzone from '@/components/PictureDropzone.vue';

    import {
        IOrganization,
        IPostOrganizationPicturesParams,
        IPostOrganizationPicturesResult,
        IPostOrganizationPicturesError,
        IDeleteOrganizationPictureParams,
    } from '@/types';

    import {
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');

    @Component<OrganizationProfilePictures>({
        components: {
            PictureDropzone,
        },
    })
    export default class OrganizationProfilePictures extends Vue {
        public innerModel!: IOrganization;
        public isUploadingPictures: boolean = false;
        public files: File[] = [];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        public deletePicture(pictureId: string) {
            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            const params: IDeleteOrganizationPictureParams = {
                organization_id: this.loggedOrganization.id,
                picture_id: pictureId,
            };

            organizationModule
                .deletePicture(params)
                .then((response: boolean) => {
                    snackbarModule.displaySuccess('L\'image a bien été supprimée');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isUploadingPictures = false);
        }

        public async uploadPictures(pictures: File[]) {
            this.isUploadingPictures = true;
            this.files = pictures;

            Promise
                .all(
                    this.files.map((file: any) => {
                        return imageHelper.resizeImage({
                            maxWidth: 1920,
                            maxHeight: 1080,
                            file: file.fileInfos,
                        });
                    }),
                )
                .then(this.sendUploadPicturesRequest)
            ;
        }

        private sendUploadPicturesRequest(files: File[]) {
            const formData = new FormData();

            files.map((file, index) => {
                formData.append(`pictures[${index}]`, file);
            });

            const params: IPostOrganizationPicturesParams = {
                organization_id: this.loggedOrganization.id,
                pictures: formData,
            };

            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            organizationModule
                .postPictures(params)
                .then((response: IPostOrganizationPicturesResult) => {
                    if (response.errors.length > 0) {
                        const failedFiles =
                            response.errors.map((value: IPostOrganizationPicturesError) => {
                                return value.file;
                            })
                                .join(', ')
                        ;
                        snackbarModule.displayError(
                            `Une erreur est survenue pendant l'envoi des images suivantes : ${failedFiles}`,
                        );
                    } else {
                        snackbarModule.displaySuccess('Vos images ont bien été ajoutées !');
                    }
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => {
                    this.isUploadingPictures = false;
                    this.files = [];
                })
            ;
        }

        private data() {
            return {
                innerModel: this.loggedOrganization ? JSON.parse(JSON.stringify(this.loggedOrganization)) : null,
            };
        }

        @Watch('loggedOrganization', {immediate: true, deep: true})
        private onLoggedOrganizationChange(newVal: IOrganization) {
            this.innerModel = newVal ? JSON.parse(JSON.stringify(newVal)): null;
        }
    }
