
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IAgenda,
        IOrganization,
        ISynchronizedAgenda,
    } from '@/types';

    import {
        AgendaModule,
        SnackbarModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');

    @Component<VetupSynchronization>({})
    export default class VetupSynchronization extends Vue {
        public synchronizedAgendas: ISynchronizedAgenda[] = [];

        public loading: boolean = false;

        public agendaIds: string[] = [];
        public originalAgendasList: any = [];
        public updatedAgendasList: any = [];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        private get hasChanges() {
            return this.agendaIds.reduce((hasChanges: boolean, agendaId: string) => {
                return hasChanges ||
                    this.originalAgendasList[agendaId].token !== this.updatedAgendasList[agendaId].token;
            }, false);
        }

        private deleteSynchronizedAgenda(agendaId: string) {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            getModule(AgendaModule, this.$store)
                .deleteSynchronizedAgenda({
                    organizationId: this.loggedOrganization.id,
                    synchronizedAgendaId: this.originalAgendasList[agendaId].sync_id,
                })
                .then(() => {
                    delete this.originalAgendasList[agendaId].token;
                    delete this.originalAgendasList[agendaId].sync_id;
                    delete this.updatedAgendasList[agendaId].token;
                    delete this.updatedAgendasList[agendaId].sync_id;

                    // Force update view
                    const agendaIds = this.agendaIds;
                    this.agendaIds = [];
                    this.agendaIds = agendaIds;
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
            ;
        }

        private saveSynchronizedAgendas() {
            this.loading = true;

            const snackbarModule = getModule(SnackbarModule, this.$store);
            const data = this.agendaIds.reduce((acc: any[], agendaId: string) => {
                const updatedAgenda = this.updatedAgendasList[agendaId];

                if (updatedAgenda.token) {
                    acc.push({
                        token: updatedAgenda.token,
                        agenda_id: agendaId,
                    });
                }

                return acc;
            }, []);

            getModule(AgendaModule, this.$store)
                .saveSynchronizedAgendas({
                    organizationId: this.loggedOrganization.id,
                    data,
                })
                .then(() => {
                    this.updateLists().then(() => this.loading = false);
                })
                .catch(() => {
                    snackbarModule.displayError();
                    this.loading = false;
                })
            ;
        }

        private async updateLists() {
            const agendasPromise = getModule(AgendaModule, this.$store)
                .fetchAgendas(this.loggedOrganization.id)
            ;

            const synchronizedAgendasPromise = getModule(AgendaModule, this.$store)
                .fetchSynchronizedAgendas(this.loggedOrganization.id)
            ;

            return Promise
                .all([agendasPromise, synchronizedAgendasPromise])
                .then(([agendas, synchronizedAgendas]) => {
                    this.originalAgendasList = {};
                    this.updatedAgendasList = {};
                    this.agendaIds = [];

                    agendas.forEach((agenda: IAgenda) => {
                        if (!agenda.id) return;
                        this.agendaIds.push(agenda.id);
                        this.originalAgendasList[agenda.id] = agenda;
                        const synchronizedAgenda = synchronizedAgendas.find((syncAgenda: ISynchronizedAgenda) => {
                                return syncAgenda.agenda_id === agenda.id;
                            })
                        ;

                        if (synchronizedAgenda) {
                            this.originalAgendasList[agenda.id].token = synchronizedAgenda.token;
                            this.originalAgendasList[agenda.id].sync_id = synchronizedAgenda.id;
                        }
                    });

                    this.updatedAgendasList = JSON.parse(JSON.stringify(this.originalAgendasList));
                })
            ;
        }

        private mounted() {
            this.updateLists();
        }
    }
