
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Model, Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        ICreateOrganizationVeterinarianParams,
        IOrganization,
        ISpecialty,
        IUpdateOrganizationVeterinarianParams,
        IVeterinarian,
    } from '@/types';

    import {
        SnackbarModule,
        VeterinarianModule,
    } from '@/store/modules';

    import {formHelper} from '@/helpers';

    const organizationNamespace = namespace('organization');
    const veterinarianNamespace = namespace('veterinarian');

    @Component<VeterinarianFormCard>({})
    export default class VeterinarianFormCard extends Vue {
        public isSubmittingVeterinarian: boolean = false;
        public veterinarianFormValid: boolean = false;

        public innerModel!: IVeterinarian;
        public email: string|null = null;

        public veterinarianEmailRules = formHelper.getVeterinarianEmailRules();
        public veterinarianFirstNameRules = formHelper.getVeterinarianFirstNameRules();
        public veterinarianLastNameRules = formHelper.getVeterinarianLastNameRules();
        public veterinarianSpecialtyRules = formHelper.getVeterinarianSpecialtyRules();

        @veterinarianNamespace.Getter('specialtiesList')
        public specialtiesList!: ISpecialty[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization|null;

        @Model('input', {type: Object}) public readonly value!: IVeterinarian;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public canGoBack!: boolean;

        get cardTitle(): string {
            if (this.innerModel.id) {
                return 'Modifier un vétérinaire';
            }

            return 'Créer un vétérinaire';
        }

        get backButtonText(): string {
            if (this.innerModel.id || !this.canGoBack) {
                return 'Annuler';
            }

            return 'Retour';
        }

        get submitButtonText(): string {
            if (this.innerModel.id) {
                return 'Modifier';
            }

            return 'Confirmer';
        }

        get params(): ICreateOrganizationVeterinarianParams | IUpdateOrganizationVeterinarianParams {
            const params: ICreateOrganizationVeterinarianParams | IUpdateOrganizationVeterinarianParams = {
                first_name: this.innerModel.first_name,
                last_name: this.innerModel.last_name,
                specialty_id: this.innerModel.specialty.id,
                organization_id: (this.loggedOrganization as IOrganization).id,
            };

            if (this.innerModel.id) {
                (params as IUpdateOrganizationVeterinarianParams).veterinarian_id = this.innerModel.id;
            } else if (this.email) {
                (params as ICreateOrganizationVeterinarianParams).email = this.email;
            }

            return params;
        }

        public backButtonClicked() {
            if (this.innerModel.id || !this.canGoBack) {
                return this.$emit('close');
            }

            return this.$emit('back');
        }

        public submitButtonClicked() {
            if (this.innerModel.id) {
                return this.updateVeterinarian();
            }

            return this.createVeterinarian();
        }

        public createVeterinarian() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.isSubmittingVeterinarian = true;

            getModule(VeterinarianModule, this.$store)
                .createVeterinarian(this.params as ICreateOrganizationVeterinarianParams)
                .then((veterinarian: IVeterinarian) => {
                    snackbarModule.displaySuccess(
                        `${veterinarian.first_name} ${veterinarian.last_name} a bien été créé(e) !`,
                    );

                    this.$emit('input', veterinarian);
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingVeterinarian = false)
            ;
        }

        public updateVeterinarian() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.isSubmittingVeterinarian = true;

            getModule(VeterinarianModule, this.$store)
                .updateVeterinarian(this.params as IUpdateOrganizationVeterinarianParams)
                .then((veterinarian: IVeterinarian) => {
                    snackbarModule.displaySuccess(
                        `${veterinarian.first_name} ${veterinarian.last_name} a bien été modifié(e) !`,
                    );

                    this.$emit('input', veterinarian);
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingVeterinarian = false)
            ;
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.value),
            };
        }
    }
