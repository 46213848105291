
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {imageHelper} from '@/helpers';

    import {
        IOrganization,
        IAgenda,
        IReason,
        ISearchParams,
        IOrganizationInstruction,
        IPostInstructionPicturesParams,
        IPostInstructionPicturesResult,
        IPostInstructionPicturesError,
        IInstruction,
    } from '@/types';

    import {
      AgendaModule,
      ReasonModule,
      SnackbarModule,
      InstructionModule,
    } from '@/store/modules';


    const agendaNamespace = namespace('agenda');
    const organizationNamespace = namespace('organization');
    const reasonNamespace = namespace('reason');
    const instructionNamespace = namespace('instruction');

    @Component<InstructionFormCard>({})
    export default class InstructionFormCard extends Vue {
        public selectedAgendas: IAgenda[] = [];
        public selectedReasons: IReason[] = [];

        public isLoadingModule: boolean = true;
        public isLoading: boolean = false;
        public isUploadingPictures: boolean = false;
        public validForm: boolean = false;

        public files: File[] = [];
        public resizeImg: any = [];
        public selectedInstruction: IInstruction|null = null;
        public passedVariables: object = {};

        public preBookingInstruction: string|null = null;
        public postBookingInstruction: string|null = null;
        public instructionTitle: string|null = null;


        @agendaNamespace.Getter('agendasList')
        public agendasList!: IAgenda[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @instructionNamespace.Getter('publicInstructionsList')
        public instructionsPublic!: IOrganizationInstruction[];

        @reasonNamespace.Getter('organizationReasonsEnabledList')
        public organizationReasonsEnabledList!: IReason[];

        public areEmpty = [
            this.validateInstructionPresence,
        ];

        public titleRules = [
          (v: string) => !!v || 'Le nom est requis',
        ];

        public timeRules = [
            (v: number) => !!v,
        ];

        public validateInstructionPresence() {
            return !!this.postBookingInstruction || !!this.preBookingInstruction || 'Veuillez renseigner au moins une consigne (avant ou après rendez-vous)';
        }

        public revalidateForm() {
          this.$nextTick(() => {
              (this.$refs.instructionForm as HTMLFormElement).validate();
          });
        }

        get renderSentence() {
          const postBook = this.selectedInstruction?.post_booking_instruction.split(':?');
          const newInstructions: IInstruction[] | null = [];

          postBook?.forEach((key: string) => {
            const item = this.selectedInstruction?.variables?.find((elm: any) => elm.name === key);

            if(item) {
              newInstructions.push({
                type: item?.type,
                values: item?.values,
                model: this.selectedInstruction?.variables,
                name: item?.name,
              } as IInstruction);
            } else {
              newInstructions.push({
                type: 'string',
                value: key,
              } as IInstruction);
            }
          });

          return newInstructions;
        }

        public createInstruction() {
          this.isLoading = true;
          const agendas = this.selectedAgendas.map((e: IAgenda) => e.id);
          const reasons = this.selectedReasons.map((e: IReason) => e.id);
          let params!: {};

          const separateObject = (obj: any) => {
            const res: any = [];
            const keys = Object.keys(obj);
            keys.forEach((key: string) => {
                res.push({
                  [key]: obj[key],
                });
            });
            return res;
          };

          switch (this.selectedInstruction?.typeof) {
            case 'dynamic':
              params = {
                organization_id: this.loggedOrganization.id,
                instruction_id: this.selectedInstruction.id,
                reasons,
                agendas,
                passed_variables: separateObject(this.passedVariables),
                status: 'enabled',
              };
              break;

            case 'free':
              params = {
                reasons,
                agendas,
                organization_id: this.loggedOrganization.id,
                instruction: {
                  pre_booking_instruction: this.preBookingInstruction,
                  title: this.instructionTitle,
                  post_booking_instruction: this.postBookingInstruction,
                  variables: [],
                },
                passed_variables: [],
                status: 'enabled',
              };
              break;
          }

          getModule(InstructionModule, this.$store).createInstruction(params as IOrganizationInstruction)
            .then((datas: IOrganizationInstruction) => {
              // tslint:disable-next-line:max-line-length
              if (this.files.length > 0) {
                this.resizeImg.then((elm: File[]) => this.sendUploadPicturesRequest(elm, datas));
              }
              this.$emit('submitted');
              this.isLoading = false;
            },
          );
        }

        public uploadFile(pictures: File[]) {
            if (!pictures) {
              return;
            }

            this.isUploadingPictures = true;
            this.files = pictures;

            this.resizeImg = Promise.all(
              this.files.map((file: File) => {
                if(file.type.match(/image.*/)) {
                  return imageHelper.resizeImage({
                      maxWidth: 1920,
                      maxHeight: 1080,
                      file,
                  });
                } else {
                  return file;
                }
              }),
            );
        }

        public sendUploadPicturesRequest(files: File[], instruction: IOrganizationInstruction) {
          const instructionModule = getModule(InstructionModule, this.$store);
          const snackbarModule = getModule(SnackbarModule, this.$store);
          const formData = new FormData();

          formData.append('organization_instruction_id', instruction.id as string);

          files.map((file, index) => {
              formData.append(`files[${index}]`, file);
          });


          const params: IPostInstructionPicturesParams = {
              organization_id: this.loggedOrganization.id,
              files: formData,
          };

          instructionModule
              .postPictures(params)
              .then((response: IPostInstructionPicturesResult) => {
                  if (response.errors.length > 0) {
                      const failedFiles =
                          response.errors.map((value: IPostInstructionPicturesError) => {
                              return value.file;
                          })
                              .join(', ')
                      ;
                      snackbarModule.displayError(`Une erreur est survenue pendant l'envoi des images suivantes : ${failedFiles}`);
                  } else {
                      snackbarModule.displaySuccess('Vos images ont bien été ajoutées !');
                  }
              })
              .catch(() => snackbarModule.displayError())
              .finally(() => {
                  this.isUploadingPictures = false;
                  this.files = [];
              })
          ;

        }

        public mounted() {
            const params: ISearchParams = {
              page: 1,
              per_page: 500,
              organization_id: this.loggedOrganization.id,
            };
            const agendasPromise = getModule(AgendaModule, this.$store).fetchAgendas(this.loggedOrganization.id);
            const reasonsPromise = getModule(ReasonModule, this.$store).fetchOrganizationReasonsEnabled(params);
            const instructionsPromise = getModule(InstructionModule, this.$store).fetchInstructionsPublic(params);

            return Promise
              .all([agendasPromise, reasonsPromise, instructionsPromise])
              .then(() => {
                this.isLoadingModule = false;
              },
            );
        }

    }
