
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Model, Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IAgenda,
        IAgendaGroup,
        IOrganization,
        IVeterinarian,
        ICreateAgendaParams,
        IUpdateAgendaParams,
        IAgendaNotificationSettings,
        ISpecies,
        IAccount,
    } from '@/types';

    import {
        AgendaModule,
        AnimalModule,
        SnackbarModule,
    } from '@/store/modules';

    import {formHelper} from '@/helpers';
    import PhoneInput from './forms/PhoneInput.vue';

    const agendaNamespace = namespace('agenda');
    const animalNamespace = namespace('animal');
    const organizationNamespace = namespace('organization');
    const accountNamespace = namespace('account');

    @Component<AgendaFormCard>({
        components: { PhoneInput },
    })
    export default class AgendaFormCard extends Vue {
        public isLoadingVeterinarians: boolean = false;
        public isSubmittingAgenda: boolean = false;
        public agendaFormValid: boolean = false;
        public agendaFormValidSwitch: boolean = false;
        public veterinarians: IVeterinarian[] = [];
        public emailnotif: string = '';
        public regexCellphone = /^(?:\+33|0)[67]\d{8}$/;

        public agendaSpeciesRules = formHelper.getAgendaSpeciesRules();
        public agendaNameRules = formHelper.getAgendaNameRules();
        public emailRules = formHelper.getEmailsRules();
        public cellPhoneRules = formHelper.getCellPhoneRules();

        public innerModel!: IAgenda;
        public notificationSettings!: IAgendaNotificationSettings;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @agendaNamespace.Getter('groupsList')
        public groupsList!: IAgendaGroup[];

        public groupSearch: string|null = null;

        @animalNamespace.Getter('speciesByTypeList')
        public speciesByTypeList!: ISpecies[];

        @animalNamespace.Getter('speciesList')
        public speciesList!: ISpecies[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization|null;

        @Model('input', {type: Object}) public readonly value!: IAgenda;

        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian!: IVeterinarian;

        get cardTitle(): string {
            if (this.innerModel.id) {
                return 'Modifier mon agenda';
            }

            return 'Paramètres de l\'agenda';
        }

        get getCellPhoneReg(): string {
            const str = this.innerModel.notification_settings?.cell_phone;
            if (!str) return 'Téléphone portable';
            const formattedStr = str.replace(/^(?:\+33|0)/, '');
            return formattedStr;
        }

        get isCellPhone(): boolean {
            let cellphone: string|undefined = this.innerModel.veterinarian?.cell_phone ? this.innerModel.veterinarian?.cell_phone : this.innerModel.notification_settings?.cell_phone;
            if (!cellphone) return false;
            cellphone = cellphone.replace(/\s/g,'')
            return this.regexCellphone.test(cellphone);
        }

        get backButtonText(): string {
            if (this.innerModel.id) {
                return 'Annuler';
            }

            return 'Retour';
        }

        get submitButtonText(): string {
            if (this.innerModel.id) {
                return 'Enregistrer';
            }

            return 'Confirmer';
        }

        public checkvalidForm() {
            if (!this.innerModel.veterinarian?.cell_phone && !this.innerModel.notification_settings?.cell_phone && this.notificationSettings.new_booking_sms === true) {
                this.agendaFormValidSwitch = true;
            }
        }

        public phoneRules = [
            (v: string) => !!v || 'Le téléphone est obligatoire',
            (v: string) => this.regexCellphone.test(v) || 'Le téléphone doit commencer par 6 ou 7 et doit etre composé de 9 chiffres',
        ];

        public getChangePhone(phone: string) {
            //ERREUR GESTION
            if (this.regexCellphone.test(phone)) {
                this.agendaFormValid = true;
                if (this.agendaFormValidSwitch) {
                    this.agendaFormValidSwitch = false;
                }
                this.notificationSettings.cell_phone = phone;
            } else {
                this.agendaFormValid = false;
            }
        }

        public filterVeterinariansWithNoAgenda(item: {first_name: string, last_name: string}, queryText: string) {
            const textOne = item.first_name.toLowerCase();
            const textTwo = item.last_name.toLowerCase();
            const searchText = queryText.toLowerCase();

            return !searchText.split(' ').some((value: string) => {
                return textOne.indexOf(value.trim()) === -1 &&
                    textTwo.indexOf(value.trim()) === -1;
            });
        }

        get params(): ICreateAgendaParams|IUpdateAgendaParams {
            const params: ICreateAgendaParams|IUpdateAgendaParams = {
                name: this.innerModel.name ? this.innerModel.name : '',
                color: this.innerModel.color,
                is_public: this.innerModel.is_public,
                is_directly_bookable: this.innerModel.is_directly_bookable,
                species: this.innerModel.species ? this.innerModel.species.map((type: ISpecies) => {
                    return type.id;
                }) : [],
                groups: this.innerModel.groups ?
                    this.innerModel.groups.map((group: IAgendaGroup) => {
                        return group.name;
                    }) : []
                ,
                veterinarian_id: (this.innerModel.veterinarian as IVeterinarian).id,
                organization_id: (this.loggedOrganization as IOrganization).id,
                notification_settings: this.notificationSettings,

            };

            if (this.innerModel.id) {
                (params as IUpdateAgendaParams).agenda_id = this.innerModel.id;
            }

            return params;
        }

        public backButtonClicked() {
            if (this.innerModel.id) {
                return this.$emit('close');
            }

            return this.$emit('back');
        }

        public submitButtonClicked() {
            if (this.innerModel.id) {
                return this.updateAgenda();
            }

            return this.createAgenda();
        }

        public formatGroups(params: IAgendaGroup[]) {
            this.innerModel.groups = params.map((group: string|IAgendaGroup) => {
                if (typeof group === 'string') {
                    return {
                        id: group,
                        name: group,
                    };
                }

                return group;
            });
        }

        public createAgenda() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.isSubmittingAgenda = true;

            getModule(AgendaModule, this.$store)
                .createAgenda(this.params as ICreateAgendaParams)
                .then((agenda: IAgenda) => {
                    snackbarModule.displaySuccess('Votre agenda a bien été créé !');

                    this.$emit('input', agenda);
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingAgenda = false)
            ;
        }

        public updateAgenda() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.isSubmittingAgenda = true;

            getModule(AgendaModule, this.$store)
                .updateAgenda(this.params as IUpdateAgendaParams)
                .then((agenda: IAgenda) => {
                    snackbarModule.displaySuccess('Votre agenda a bien été modifié !');

                    this.$emit('input', agenda);
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingAgenda = false)
            ;
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.value),
                notificationSettings: Object.assign(
                    {},
                    this.value.notification_settings ?? {
                        new_booking_email: false, new_booking_sms: false, cell_phone: '', email: '',
                    },
                ),
            };
        }

        private mounted() {
            getModule(AgendaModule, this.$store)
                .fetchAgendasGroups((this.loggedOrganization as IOrganization).id)
            ;
            getModule(AnimalModule, this.$store).computeSpeciesByTypeList();

            if (!this.notificationSettings.new_booking_email) {
                this.notificationSettings.email = this.loggedAccount.email;
            }
            if (!this.notificationSettings.new_booking_sms && this.innerModel.veterinarian?.cell_phone) {
                this.notificationSettings.cell_phone = this.innerModel.veterinarian.cell_phone;
            }
        }

        private created() {
            if (!this.innerModel.id) {
              return;
            }
            if (!this.notificationSettings.new_booking_email) {
                this.notificationSettings.email = this.loggedAccount.email;
            }

            if (!this.notificationSettings.new_booking_sms && this.innerModel.veterinarian?.cell_phone) {
                this.notificationSettings.cell_phone = this.innerModel.veterinarian.cell_phone;
            }

            this.isLoadingVeterinarians = true;

            this.$api.organization
                .veterinariansList((this.loggedOrganization as IOrganization).id)
                .then((veterinarians: IVeterinarian[]) => {
                    this.veterinarians = veterinarians.filter((veterinarian: IVeterinarian) => {
                            return veterinarian.agendas_count === 0;
                        })
                    ;
                    this.veterinarians.push(this.innerModel.veterinarian as IVeterinarian);
                    this.isLoadingVeterinarians = false;
                })
            ;
        }
    }
