
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IOrganization,
        IOrganizationRule,
        IReason,
        IRule,
        IRuleVariables,
        ISearchParams,
    } from '@/types';

    import {
      ReasonModule,
      RuleModule,
      SnackbarModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');
    const reasonNamespace = namespace('reason');
    const ruleNamespace = namespace('rule');

    @Component<RuleFormCard>({})
    export default class RuleFormCard extends Vue {
        public selectedReasons: IReason[] = [];
        public selectedReasonIssue: IReason | null = null;

        public isLoadingModule: boolean = true;
        public isLoading: boolean = false;
        public validForm: boolean = false;
        public selectedRule: IRule | null = null;

        public ruleTitle: string|null = null;
        public passedVariables: object = {};
        public animalAge: number|null = null;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @reasonNamespace.Getter('organizationReasonsEnabledList')
        public organizationReasonsEnabledList!: IReason[];

        @ruleNamespace.Getter('publicRulesList')
        public rulesPublic!: IOrganizationRule[];

        public selectRule = [
          (v: string) => !!v || 'Le nom est requis',
        ];

        get setIssueComment() {
            const rule = this.selectedRule?.issue_comment ? this.selectedRule.issue_comment.split(':?'): '';
            const newRules: IRule[] = [];
            if(!rule) return;
            rule.forEach((key: string) => {
                const item = this.selectedRule?.variables?.find((elm: IRuleVariables) => elm.name === key);

                if(item) {
                    newRules.push({
                        type: item.type,
                        values: item.values,
                        model: this.selectedRule?.variables,
                        name: item.name,
                        label: item.label,
                    } as IRule);

                } else {
                    newRules.push({
                        type: 'string',
                        value: key,
                    } as IRule);
                }
            });

            return newRules;
        }

        get renderSentence() {
            const rule = this.selectedRule?.rule.split(':?');
            const newRules: IRule[] = [];
            if (!rule) return;
            rule.forEach((key: string) => {
                const item = this.selectedRule?.variables?.find((elm: IRuleVariables) => elm.name === key);

                if(item) {
                    newRules.push({
                        type: item?.type,
                        values: item?.values,
                        model: this.selectedRule?.variables,
                        name: item?.name,
                        label: item?.label,
                    } as IRule);

                } else {
                    newRules.push({
                        type: 'string',
                        value: key,
                    } as IRule);
                }
            });

          return newRules;
        }

        public ageRule = [
            (v: number) => (v < 101 && v > 0) || 'L\'age doit être compris entre 1 et 100',
        ];

        public createRule() {
            const snackbarModule = getModule(SnackbarModule, this.$store);
            const reasons = this.selectedReasons.map((e: IReason) => e.id);
            let params!: {};
            let reasonIssue: string | null = null;

            this.isLoading = true;

            if (this.selectedReasonIssue) {
                reasonIssue = this.selectedReasonIssue.id;
            }

            const separateObject = (obj: any) => {
                const res: any = [];
                const keys = Object.keys(obj);
                keys.forEach((key: string) => {
                    res.push({[key]: obj[key]});
                });

                return res;
            };

            if (this.selectedRule?.variables) {
                params = {
                    organization_id: this.loggedOrganization.id,
                    reasons,
                    passed_variables: separateObject(this.passedVariables),
                    rule_id: this.selectedRule.id,
                    organization_reason_issue_id: reasonIssue,
                };
            } else {
                params = {
                    organization_id: this.loggedOrganization.id,
                    reasons,
                    passed_variables: [],
                    rule_id: this.selectedRule?.id,
                    organization_reason_issue_id: reasonIssue,
                };
            }

            getModule(RuleModule, this.$store)
                .createRule(params as IOrganizationRule).then(() => {
                    this.isLoading = false;
                    this.$emit('submitted');
                    snackbarModule.displaySuccess('La règle à bien été ajouté');
                },
            );

        }

        public mounted() {
            const params: ISearchParams = {
              page: 1,
              per_page: 500,
              organization_id: this.loggedOrganization.id,
            };

            const reasonsPromise = getModule(ReasonModule, this.$store).fetchOrganizationReasonsEnabled(params);
            const rulesPromise = getModule(RuleModule, this.$store).fetchRulesPublic(params);

            return Promise
              .all([reasonsPromise, rulesPromise])
              .then(() => {
                    this.isLoadingModule = false;
              },
            );
        }
    }
