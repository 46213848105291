
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';

    @Component<ConfirmDialog>({})
    export default class ConfirmDialog extends Vue {
        @Prop({
            type: Boolean,
            required: true,
        })
        private isSubmitting!: boolean;

        @Prop({
            type: Boolean,
            required: false,
            default: true,
        })
        private isFormValid!: boolean;

        @Prop({
            type: String,
            default: 'success',
        })
        private type!: string;

        @PropSync('opened', {
            type: Boolean,
            default: () => false,
        })
        private openedSync!: boolean;
    }
