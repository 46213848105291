
import Vue from 'vue';
import Component from 'vue-class-component';
import {namespace} from 'vuex-class';
import {getModule} from 'vuex-module-decorators';

import {
    IInvoice,
    IOrganization,
    IOrganizationSubscription,
    IPlaceSuggestion,
    IUpdateSubscriptionParams,
    ICreateSetupIntentParams
} from '@/types';

import {addressHelper, formHelper, stripeHelper, filterObject} from '@/helpers';

import PlaceInput from '@/components/forms/PlaceInput.vue';
import IbanInput from '@/components/forms/IbanInput.vue';
import StripeCard from '@/components/forms/StripeCard.vue';

import {OrganizationModule, SnackbarModule} from '@/store/modules';

const organizationNamespace = namespace('organization');

@Component<OrganizationBilling>({
    components: {
        PlaceInput,
        IbanInput,
        StripeCard
    },
})
export default class OrganizationBilling extends Vue {
    public paymentOwnerUseOrganizationAddress: boolean = false;
    public paymentOwnerName: string|null = null;
    public paymentOwnerEmail: string|null = null;
    public paymentOwnerAddress: IPlaceSuggestion|null = null;
    public consumptionThisMonth: number = 0;
    public discount: string|null = null;

    public editIban: boolean = false;
    public isIbanValid: boolean = false;
    public errorMessage: string = '';
    public formValid: boolean = false;
    public submittingForm: boolean = false;

    public subscriptionIsFetched: boolean = false;
    public subscription: IOrganizationSubscription|null = null;
    public invoicesAreFetched: boolean = false;
    public modifyPayementMethod: boolean = false;

    public stripeIban: stripe.elements.Element|null = null;
    public stripeCard: stripe.elements.Element|null = null;
    public confimElmStripe: boolean = false;
    public last4: string|null = null;
    public payment_method_type: string|null = null;
    public clientSecretStripe: string = '';

    public ownerNameRules = formHelper.getOwnerNameRules();
    public ownerEmailRules = formHelper.getOwnerEmailRules();
    public ownerAddressRules = formHelper.getOwnerAddressRules();
    public dateTimeFormat = new Intl.DateTimeFormat('fr-FR', {
        weekday: 'short',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    public tabs: string = '';

    public itemsNav = [
        {
            title: 'Informations Bancaires',
            value: 'activ',
        },
        {
            title: 'Factures',
            value: 'inactiv',
        },
    ];

    @organizationNamespace.Getter('loggedOrganization')
    public loggedOrganization!: IOrganization;

    @organizationNamespace.Getter('invoiceList')
    public invoiceList!: IInvoice[];

    private stripeModule = Stripe(process.env.VUE_APP_STRIPE_KEY as string);
    private organizationModule = getModule(OrganizationModule, this.$store);
    private snackbarModule = getModule(SnackbarModule, this.$store);

    get billingAddress() {
        if (this.paymentOwnerUseOrganizationAddress) {
            return {
                id: this.loggedOrganization.location.external_id,
                formatted: addressHelper.formatForStripe(this.loggedOrganization.address),
            };
        }

        const parsedPlace = addressHelper.parseRawData(this.paymentOwnerAddress!);
        return {
            id: this.paymentOwnerAddress?.raw_data.place_id,
            formatted: addressHelper.formatForStripe(parsedPlace),
        };
    }

    get invoiceHeaders() {
        return [
            {
                text: 'Date',
                value: 'date',
                sortable: true, 
                order: 'desc'
            },
            {
                text: 'Numéro',
                value: 'number',
            },
            {
                text: 'Montant TTC',
                value: 'total',
            },
            {
                text: 'Statut',
                value: 'status',
            },
            {
                text: 'Télécharger',
                value: 'url',
                sortable: false,
            },
        ];
    }

    public submit() {
        this.submittingForm = true;
        
        const subscriptionParams: ICreateSetupIntentParams = {
            email: this.paymentOwnerEmail ? this.paymentOwnerEmail : '' as string,
            owner_name: this.paymentOwnerName ? this.paymentOwnerName as string : '',
            place_id: this.billingAddress.id!,
            price_id: this.modifyPayementMethod ? process.env.VUE_APP_STRIPE_PRICE_ID : '',
        }
        if(this.modifyPayementMethod) {
            this.setupIntentStripe(subscriptionParams);
        } else {
            this.updateSubscription(subscriptionParams as IUpdateSubscriptionParams);
        }
    }

    public formatDate(date: string) {
        return this.dateTimeFormat.format(new Date(date));
    }

    public onEventChange(event: any) {
        this.confimElmStripe = event.complete;
        this.errorMessage = event.error ? event.error.message : '';
    }

    public setIbanEditable(editable: boolean = true) {
        this.editIban = editable;
    }

    public initForm(subscription: IOrganizationSubscription) {
        this.paymentOwnerEmail = subscription.email;
        this.paymentOwnerName = subscription.owner_name;
        this.paymentOwnerAddress = {
            title: subscription.address.label,
            raw_data: {
                place_id: subscription.location.external_id,
            },
        };
        this.last4 = subscription.last_4;
        this.payment_method_type = subscription.payment_method_type;

        this.discount = subscription.discount;
        this.consumptionThisMonth = subscription.consumption_this_month;
    }

    public getInvoice(id: string) {
        this.organizationModule
            .getInvoice({
                organization_id: this.loggedOrganization.id,
                invoice_id: id,
            })
            .then((invoice: IInvoice) => {
                window.open(invoice.url, '_blank');
            })
        ;
    }

    public mounted() {
        this.organizationModule
            .fetchSubscription(this.loggedOrganization.id)
            .then((result) => {
                this.initForm(result);
                this.subscription= result;
                this.subscriptionIsFetched = true;
            });

        this.organizationModule
            .fetchInvoices(this.loggedOrganization.id)
            .then(() => {
                this.invoiceList.sort((a, b) => {
                    return new Date(b.date).getTime() - new Date(a.date).getTime();
                });
                this.invoicesAreFetched = true;
            });
    }

    

    public StripeSync() {
        return new Promise((resolve, reject) => {
            let owner = null;
            if (this.payment_method_type === 'sepa_debit') {
                owner = {
                    address: this.billingAddress.formatted,
                    name: this.paymentOwnerName ? this.paymentOwnerName as string : '',
                    email: this.paymentOwnerEmail ? this.paymentOwnerEmail : '',
                };
            }
            const stripeElm = this.payment_method_type === 'sepa_debit' ?  this.stripeIban :  this.stripeCard;
            resolve(stripeHelper.confirmStripeCardPayement(this.clientSecretStripe, this.stripeModule, stripeElm as any, this.payment_method_type as string, owner || null));
        });
    }

    public setupIntentStripe(subscriptionParams: ICreateSetupIntentParams) {
        this.confimElmStripe=false; 
        this.errorMessage='';

        getModule(OrganizationModule, this.$store)
            .createSetupIntent({
                organizationId: (this.loggedOrganization as IOrganization).id,
                subscription: subscriptionParams,
            }).then((data)=> {
                if(!data.secret) return;
                this.clientSecretStripe = data.secret;
                if(this.clientSecretStripe) {                    
                    this.StripeSync().then(()=>{
                        if(this.modifyPayementMethod) {
                            this.updateSubscription(subscriptionParams as IUpdateSubscriptionParams);
                        }
                    })
                }
            })
    }

    public updateSubscription(subscriptionParams: IUpdateSubscriptionParams) {
        this.organizationModule
                .updateSubscription({
                    organizationId: this.loggedOrganization.id,
                    subscription: subscriptionParams,
                })
                .then((result) => {
                    this.initForm(result);
                    this.snackbarModule.displaySuccess(
                        'Vos informations bancaires ont bien été mises à jour !',
                    );
                    setTimeout(()=> {
                        window.location.reload();
                    }, 1000)
                })
                .catch((error) => {
                    this.snackbarModule.displayError(error.message);
                })
            .finally(() => {
                this.modifyPayementMethod = false;
                this.submittingForm = false;
                this.editIban = false;
            });
    }
}
