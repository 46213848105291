
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';
    import {formHelper} from '@/helpers';

    import {
        IOrganization,
        IPostOrganizationSocialsParams,
    } from '@/types';

    import {
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');

    @Component<OrganizationProfileSocial>({})
    export default class OrganizationProfileSocial extends Vue {
        public formIsChanged: boolean = false;
        public isSubmittingSocials: boolean = false;

        public innerModel!: IOrganization;

        public webUrlRules = formHelper.getOrganizationWebUrlRules();
        public partnerRules = formHelper.getOrganizationPartnerRules(false);

        public partnerList: object[] = [
            {id: 0, name: 'Aucun'},
            {id: 1, name: 'Chronovet', label:'Renseignez votre identifiant chronovet' },
            {id: 2, name: 'Autre boutique en ligne',  label:'https://ma-boutique-en-ligne.com'  },
        ];

        public partnerSelected: any = null;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        public submitSocials() {
            this.isSubmittingSocials = true;

            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            const params: IPostOrganizationSocialsParams = {
                organization_id: this.loggedOrganization.id,
                social_links: this.innerModel.social_links,
            };

            if (this.partnerSelected.id === 0) {
                params.social_links.chronovet_id = null;
                params.social_links.shop_link = null;
            } else if (this.partnerSelected.id === 1) {
                params.social_links.chronovet_id = Number(params.social_links.chronovet_id);
                params.social_links.shop_link = null;
            } else if (this.partnerSelected.id === 2) {
                params.social_links.chronovet_id = null;
            }

            organizationModule
                .postSocials(params)
                .then(() => {
                    snackbarModule.displaySuccess(
                        `Le lien du partenaire de ${this.loggedOrganization.name} à bien été modifié !`,
                    );
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
                .finally(() => {
                    this.isSubmittingSocials = false;
                })
            ;
        }

        get isChanged() {
            const currentShopLink = this.loggedOrganization.social_links.shop_link;
            const currentChronovetId = this.loggedOrganization.social_links.chronovet_id;
            const newShopLink = this.innerModel.social_links.shop_link;
            const newChronovetId = this.innerModel.social_links.chronovet_id;

            if (this.partnerSelected.id === 0) {
                return currentShopLink || currentChronovetId;
            } else if (this.partnerSelected.id === 1) {
                return currentChronovetId?.toString() !== newChronovetId?.toString();
            } else if (this.partnerSelected.id === 2) {
                return currentShopLink !== newShopLink;
            }

            return false;
        }


        private data() {
            return {
                innerModel: this.loggedOrganization ? JSON.parse(JSON.stringify(this.loggedOrganization)) : null,
            };
        }

        @Watch('loggedOrganization', {immediate: true})
        private onLoggedOrganizationChange(newVal: any) {
            this.innerModel = newVal ? JSON.parse(JSON.stringify(newVal)) : null;

            if (this.innerModel?.social_links?.chronovet_id) {
                this.partnerSelected = this.partnerList[1];
            } else if (this.innerModel?.social_links?.shop_link) {
                this.partnerSelected = this.partnerList[2];
            } else {
                this.partnerSelected = this.partnerList[0];
            }
        }

    }
